import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner
} from "reactstrap";

import Breadcrumb from "../../../components/Breadcrumb";
import {
  clearNewOrder,
  getNewOrder,
  removeStatus
} from "../../../store/actions";
import Banner from "./Banner";
import "./newOrder.css";
import ControleRemoto from "./ControleRemoto";
import Cupons from "./Cupons";
import Frete from "./Frete";
import PedidoEmitido from "./PedidoEmitido";
import RegisterUser from "./RegisterUser";
import Resumo from "./Resumo";
import Saldo from "./Saldo";
import PartnerCompany from "./PartnerCompany";
import { toast } from "react-toastify";
import Cart from "./Cart";
import Status from "../../../components/StatusCar";
import { useProfile } from "../../../hooks";
import { useParams } from "react-router-dom";
import { apiClient } from "../sunbeam-at-your-address/helpers/importer.helper";
import ModalAlert from "../../components/modal";
import { useDataLayer } from "../../../hooks/DataLayerHooks";
import InsertProduct from "./InsertProduct";
import ShippingData from "./ShippingData";
import { dateNow } from "../../../utils";

const SolarForaKit = ({ history }) => {
  const dispatch = useDispatch();
  const { newOrder, loading, order, emissaoPedido, status } = useSelector(
    state => {
      return {
        newOrder: state.Order.newOrder.newOrder,
        loading: state.Order.loading,
        order: state.Order.orderActive.order,
        emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
        status: state.KitsNew.status,
      };
    }
  );
  const { pushToDataLayer } = useDataLayer();
  const { userProfile } = useProfile();
  const { token } = useParams();
  const [frete, setFrete] = useState({});
  const [valores, setValores] = useState({});
  const [valorRt, setValorRt] = useState({});
  const [valCashbackNoRetention, setValCashbackNoRetention] = useState({});
  const [discountValue, setDiscountValue] = useState();
  const [ocultarRt, setOcultarRt] = useState(false);
  const [nfIsOpen, setNfIsOpen] = useState(false);
  const [obsIsOpen, setObsIsOpen] = useState(false);
  const [cepCliente, setCepCliente] = useState(null);
  const [pagtoSelected, setPagtoSelected] = useState([]);
  const [client, setClient] = useState({});
  const [saldo, setSaldo] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState({});
  const [filterPedido, setFilterPedido] = useState({
    Nomecondpagto: "BOLETO A VISTA SOLAR",
    Nometabela_preco: "SOLAR",
    condpagto: "885",
    tabela_preco: "043",
    vendedor2: "",
  });

  const [pedidoEmitido, setPedidoEmitido] = useState(false);
  const [type, setType] = useState("order");

  const [, setObsInterna] = useState("");
  const [, setObsNF] = useState("");

  const [dataLeed, setDataLeed] = useState([]);

  const [formVisible, setFormVisible] = useState({
    prospect: true,
    client: false,
    partner: false,
  });
  const [loadingProspect, setLoadingProspect] = useState(false);

  const [parceiros, setParceiros] = useState([]);

  const [errosDataProspectAndPartner, setErrosDataProspectAndPartner] =
    useState({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });

  const valTot = Somar(Object.values(valores));
  const valRt = Somar(Object.values(valorRt));
  const valRtNoRetention = Somar(Object.values(valCashbackNoRetention));
  const [openModal, setOpenModal] = useState(true);

  const [valTotAndRt, setValTotAndRt] = useState({});

  const [cepSelected, setCepSelected] = useState(1000);
  const [getTheHighestForecastDate, setGetTheHighestForecastDate] =
    useState(null);

  const [productsVisible, setProductsVisible] = useState(false);
  const [percentCashBackAll, setPercentCashBackAll] = useState("0");
  const [percentCashBackAllOnBlur, setPercentCashBackAllOnBlur] =
    useState(false);
  const [acrescimo, setAcrescimo] = useState(0);
  const [termOfAgreement, setTermOfAgreement] = useState({
    confirm: false,
    date: "",
    hour: "",
  });
  const [changeFormOfPayment, setChangeFormOfPayment] = useState(false);
  const [futureSaleValue, setFutureSaleValue] = useState(false);
  const [triangularSale, setTriangularSale] = useState(false);
  const [shippingZipCode, setShippingZipCode] = useState("");
  const [shippingAddress, setShippingAddress] = useState({});
  const [shippingValues, setShippingValues] = useState({});

  const shippingTotalValue = Object.values(shippingValues).reduce((total, value) => total + parseFloat(value), 0);

  //constante para evitar o looping de frete
  const [waitingToChangeCartItems, setWaitingToChangeCartItems] =
    useState(false);

  const seguro = [
    { type: "seguroEng", name: "", percent: 0.5, valor: 0, checked: false },
    { type: "seguroRd", name: "", percent: 1, valor: 0, checked: false },
  ];

  var total = newOrder.addedItems.reduce(getTotal, 0) + valRtNoRetention;

  function getTotal(total, item) {
    return total + item.produto[0].PRCRT;
  }

  useEffect(() => {
    if (parceiros.length > 0) {
      setFilterPedido(state => {
        return { ...state, vendedor2: parceiros[0].codigo };
      });
    }
  }, [parceiros]);

  const handleClickModalConfirm = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();

    setTermOfAgreement({ confirm: true, date: date, hour: hours });

    setOpenModal(false);
  };

  //verificar se o retorno da requisição getNewOrder é solar
  const isSolarRequestNewOrder = () => {
    var requestIsSolar = newOrder.tabelaInicial == "043";
    if (!requestIsSolar) {
      toast.warning(
        "ATENÇÃO! Tabela diferente da solar! Saia e entre novamente",
        {
          theme: "colored",
        }
      );
    }
  };

  useEffect(() => {
    if (newOrder?.tabelaInicial) {
      isSolarRequestNewOrder();
    }
  }, [newOrder.tabelaInicial]);

  const getDataLeed = async () => {
    try {
      const resp = await apiClient.get(`/api/solar/lead/${token}`, {
        type: "order",
      });

      setDataLeed(resp);
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };

  useEffect(() => {
    if (token) getDataLeed();
  }, []);

  useEffect(() => {
    setObsInterna(order?.ObservacaoInterna);
    setObsNF(order?.ObservacaoNota);
  }, [order]);

  useEffect(() => {
    dispatch(clearNewOrder());
    dispatch(removeStatus());
    setFrete({});
    setValores({});
    setValorRt({});
    setValCashbackNoRetention({});
    setOcultarRt(false);
    setNfIsOpen(false);
    setFutureSaleValue(false);
    setTriangularSale(false);
    setObsIsOpen(false);
    setCepCliente(null);
    setPagtoSelected([]);
    setClient({});
    setSaldo(0);
    setItensCarrinho({});
    setPedidoEmitido(false);
    setType("order");
    dispatch(getNewOrder("CODEMPRESA=02&CODFILIAL=02", history));
  }, []);

  const [itHasForecast, setItHasForecast] = useState(0);

  useEffect(() => {
    setItHasForecast(newOrder.addedItems.filter(item => {
      const produto = item?.produto[0];

      const isInverter = produto?.nome?.includes("INV");

      const minimumStock = (isInverter ? 10 : 1000) + produto?.estoqueFuturo;

      return (
        (produto?.previsao && produto?.qtdEstoque < produto?.estoqueFuturo) ||
        (produto?.previsao &&
          produto?.leadTime &&
          produto?.qtdEstoque < minimumStock)
      );
    }));
  }, [newOrder.addedItems]);

  let pedido = {
    client,
    type_order: "order",
    product_type: "",
    budget_infos: {
      codigo_cliente: 0,
      tipo_cliente: 0,
    },
    items: retornaItems(),
    itemsValTot: valTot,
    shipping: {
      id: frete?.id || "",
      shipping_selected: {
        delivery_delay: frete?.delivery_delay || "",
        id: frete?.id || "",
        price: frete?.price || "",
        description: frete?.description || "",
        price_percent: frete?.price_percent || "",
        usado_percentual: frete?.usado_percentual || "",
        frete_gratis: frete?.frete_gratis || "",
      },
      price: frete?.price || "",
    },
    filters: filterPedido,
    services: {
      product_type: "",
      ambientes: [],
    },
    discountcashback: saldo,
    ...discountValue,
    CODEMPRESA: "02",
    CODFILIAL: "01",
    termOfAgreement,
    status,
    seguro,
    futureSale: futureSaleValue,
    triangularSale,
    typeOfKit: "fora-kit",
  };

  if (triangularSale) {
    pedido = {
      ...pedido,
      shippingZipCode,
      shippingAddress,
      shippingTotalValue: shippingValues
    };
  }

  const largeAccountCode = ['Z42', 'Z43', 'Z44'];

  const completedShippingValues = pedido.items.length == Object.values(shippingValues).length;

  function retornaItems() {
    let items = [];

    newOrder.addedItems.map(value => {
      var arraySubTotal = [];

      arraySubTotal.push({ [value?.produto[0]?.sku]: value.produto[0].subTotal });

      function getEstoques(es) {
        let result =
          value.produto.PRDDISPS?.STRETPRDFILADIAS?.find(
            i => i.CODFILPRD === es
          ) || "";
        return result?.ESTOQUE || 0;
      }

      let produto = {
        ...value.produto[0],
        ALIMENTACAO: "",
        ALTURA: "",
        KWP: 2.03,
        ARMAZEM: "",
        BITOLALIQ: "",
        BITOLASUC: "",
        CAPACIDADE: "05",
        CATEGORIA: "405",
        CCONDENSA: "",
        CEVAPORA: "",
        CICLO: "1",
        CODEAN: "",
        CODEMPRESA: "02",
        CODFILIAL: "02",
        CODLEGADO: "",
        CODPRODUTO: value?.produto[0]?.sku,
        CONDPAGTO: "",
        COR: "X",
        CORRENTE: 0,
        DESCRICAO: value.produto[0].nome,
        DESCRICAO_COMPLETA:
          "QTDE MODULO: 05\nMÓDULO: MONO-PERC RISEN 405W (F:30 MM)\nNÚMERO DE CÉLULAS: 120 MONO HALF CELL\nMEDIDAS MOD: 1754 X 1096 X 30 mm\n\n \nQTDE INVERSOR 1: 1\nINVERSOR 1: SOFAR 1600TL-G3\nFASE/TENSÃO: MONOFÁSICO 220V\nMPPT: 1\nQTDE STRINGBOX 1: 1\nSTRINGBOX 1: STRINGBOX SB07A - 1E/1S SECCIONADORA NO INVERSOR\n\n \nQTDE PAR CONECTOR MC4: 2\n\n \nQTDE CABO 6MM-1800V VERMELHO (METROS): 15\nQTDE CABO 6MM-1800V PRETO (METROS): 15\n\n \nQTDE ESTRUTURA 1: 10\nESTRUTURA 1: KIT SUPORTE PARA TELHADO COLONIAL\nQTDE ESTRUTURA 2: 4\nESTRUTURA 2: KIT TERMINAL FINAL 35MM\nQTDE ESTRUTURA 3: 6\nESTRUTURA 3: KIT TERMINAL INTERMEDIÁRIO 39/44MM - PAR\nQTDE ESTRUTURA 4: 4\nESTRUTURA 4: PERFIL DE ALUMÍNIO 3.40M\nQTDE ESTRUTURA 5: 4\nESTRUTURA 5: KIT DE EMENDAS E PARAFUSOS INOX 8X12",
        FASE: "2",
        GARANTEX: 0,
        GARANTIA: 0,
        GRUPO: "INO",
        LARGURA: 0,
        MARCA: "",
        NCM: "",
        ORIGEM: "",
        PESOBRUTO: 0,
        PESOLIQUI: 0,
        PRDDISPS: {
          STRETPRDFILADIAS: [
            {
              CODFILPRD: "02",
              ESTOQUE: 1,
            },
          ],
        },
        PREVISAOC7: "",
        PROFUNDIDADE: 0,
        RUIDO: 0,
        SELOA: "",
        TABELAPRC: "",
        TIPOPROD: "",
        UNIDADEMED: "",
        VOLUME: 0,
        ITEM: "",
        AMOUNT: value.produto[0].qtd ? value.produto[0].qtd : 1,
        VALORRT: 0,
        PRCRT: value.produto[0].PRCRT,
        subtotal: arraySubTotal,
        maskPrcVenda: value.produto[0].precoCliente,
        PERCENT: 0,
        PRCVENDA: value.produto[0].precoCliente,
        ESTOQUE_CD01: getEstoques("01"),
        ESTOQUE_CD02: getEstoques("02"),
        ESTOQUE_CD03: getEstoques("03"),
        ESTOQUE_CD04: getEstoques("04"),
        /* previsao: previsao, */
        precos: {
          cd:
            value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.CODFILPRD ||
            "",
          preco: value.precoVenda,
          diferente: "N",
        },
        PRECODIFER: value.produto[0].precoCliente || "",
        ESTOQUE:
          value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.ESTOQUE || "",
        instalacao: false,
        previsao: itHasForecast.some(item => item.produto[0].sku == value.produto[0].sku) ? value.produto[0].previsao : null,
        ...itensCarrinho[value.produto[0].nome],
      };

      items.push(produto);
    });

    return items;
  }

  function Somar(array) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      total += array[i];
    }
    return total;
  }

  const addValTot = valor => {
    setValores(valor);
  };

  const totalValue = () => {
    const discount =
      discountValue?.typerulediscount == 1
        ? (parseFloat(total) / 100) * Number(discountValue?.discountValue)
        : discountValue?.discountValue;

    var totalDiscount = parseFloat(total) - parseFloat(discount || 0);

    return parseFloat(
      parseFloat(totalDiscount) +
      parseFloat(frete?.price || 0) -
      parseFloat(saldo)
    );
  };

  useEffect(() => {
    if (frete?.id) {
      pushToDataLayer(
        'add_shipping_info',
        {
          ecommerce: {
            currency: 'BRL',
            value: totalValue(),
            selectedShipping: frete,
            items: newOrder?.addedItems.map(product => {
              const produto = Array.isArray(product.produto) ? product.produto[0] : product.produto;
              return {
                item_id: produto.sku || '',
                item_name: produto.nome || '',
                price: produto.PRCRT || 0,
                quantity: produto.qtd || 0
              }
            })
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [frete]);

  const addFrete = valor => {
    setFrete(valor);
  };

  const changeType = type => {
    setType(type);
  };

  const changeCepCliente = cep => {
    setCepCliente(cep);
  };
  const addValorRt = valor => {
    setValorRt(prevState => {
      return {
        ...prevState,
        [valor.nome]: valor.valCashBack,
      };
    });
  };

  const addValorRtNoRetention = valor => {
    setValCashbackNoRetention(prevState => {
      return {
        ...prevState,
        [valor.nome]: valor.valCashBackNoRentention,
      };
    });
  };

  const changeSaldo = saldo => {
    setSaldo(Number(saldo));
  };

  const changeClient = client => {
    setClient(prev => ({ ...prev, ...client }));
  };

  const changePedidoEmitido = () => {
    setPedidoEmitido(!pedidoEmitido);
  };

  useEffect(() => {
    if (emissaoPedido?.data?.success) {

      pushToDataLayer(
        type == 'budget' ? 'solicitar_orcamento' : 'purchase',
        {
          ecommerce: {
            currency: 'BRL',
            value: totalValue(),
            transaction_id: emissaoPedido.data.CODPEDIDO,
            shipping: emissaoPedido.data.FRETE,
            vendedor1: emissaoPedido.data.VENDEDOR1,
            vendedor2: emissaoPedido.data.VENDEDOR2,
            vendedor3: emissaoPedido.data.VENDEDOR3,
            items: newOrder?.addedItems.map(product => {
              const produto = Array.isArray(product.produto) ? product.produto[0] : product.produto;
              return {
                item_id: produto.sku || '',
                item_name: produto.nome || '',
                price: produto.PRCRT || 0,
                quantity: produto.qtd || 0
              }
            })
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [emissaoPedido]);

  const renderView = () => {
    return (
      <React.Fragment>
        <Breadcrumb title="Página Inicial" breadcrumbItem="Emissão do pedido" />
        <Row>
          <Col md={12}>
            <div className="mb-4">
              <Banner />
            </div>
          </Col>
        </Row>

        <div></div>
        {loadingProspect && (
          <div className="carregando-emissao">
            <Spinner />
          </div>
        )}

        {loading && (
          <div className="carregando-emissao">
            <Spinner />
          </div>
        )}
        {/* VERIFICA SE O PEDIDO FOI EMITIDO  */}
        {!emissaoPedido?.data?.success ? (
          <React.Fragment>
            <Row>
              <InsertProduct
                addValTot={addValTot}
                valores={valores}
                acrescimo={acrescimo}
                setProductsVisible={setProductsVisible}
                setAcrescimo={setAcrescimo}
                setWaitingToChangeCartItems={setWaitingToChangeCartItems}
                setFilterPedido={setFilterPedido}
                setPagtoSelected={setPagtoSelected}
                setChangeFormOfPayment={setChangeFormOfPayment}
                largeAccountCode={largeAccountCode}
              />
            </Row>
            <hr style={{ borderTop: "1.5px solid #D5DCE5" }} />
            <Row className="mt-4" id="carrinho">
              {productsVisible && (
                <Cart
                  addValTot={addValTot}
                  valores={valores}
                  addValorRt={addValorRt}
                  ocultarRt={ocultarRt}
                  setItensCarrinho={setItensCarrinho}
                  percentCashBackAll={percentCashBackAll}
                  setPercentCashBackAll={setPercentCashBackAll}
                  setPercentCashBackAllOnBlur={setPercentCashBackAllOnBlur}
                  percentCashBackAllOnBlur={percentCashBackAllOnBlur}
                  setValTotAndRt={setValTotAndRt}
                  valTotAndRt={valTotAndRt}
                  changeFormOfPayment={changeFormOfPayment}
                  addValorRtNoRetention={addValorRtNoRetention}
                  total={total}
                  itHasForecast={itHasForecast}
                  shippingValues={shippingValues}
                  setShippingValues={setShippingValues}
                  triangularSale={triangularSale}
                />
              )}
            </Row>

            <Row id="sentinel" className="mt-4">
              <RegisterUser
                valTot={valTot}
                changeCepCliente={changeCepCliente}
                pagtoSelected={pagtoSelected}
                changeClient={changeClient}
                client={client}
                formVisible={formVisible}
                setFormVisible={setFormVisible}
                errosDataProspectAndPartner={errosDataProspectAndPartner}
                setErrosDataProspectAndPartner={
                  setErrosDataProspectAndPartner
                }
                dataLeed={dataLeed}
                parceiros={parceiros}
                setParceiros={setParceiros}
                setFrete={setFrete}
                triangularSale={triangularSale}
              />
            </Row>
            <Row className="mt-3" id="notas">
              <Cupons setDiscountValue={setDiscountValue} />
            </Row>
            <Row className="mt-3">
              <Saldo changeSaldo={changeSaldo} />
            </Row>
            <Row className="mt-3">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={11}>
                      <p
                        className="mb-0 d-flex align-items-center"
                        style={{ fontWeight: "bold" }}
                      >
                        <i
                          className="ph-notepad"
                          style={{
                            color: "#FF601A",
                            marginRight: 20,
                            fontSize: 25,
                          }}
                        ></i>
                        Adicionar Observação Interna:
                      </p>
                    </Col>
                    <Col md={1}>
                      <a
                        href="#"
                        style={{
                          width: "fit-content",
                          marginLeft: "auto",
                          display: "block",
                        }}
                        onClick={event => {
                          event.preventDefault();
                          setObsIsOpen(!obsIsOpen);
                        }}
                      >
                        {obsIsOpen ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </a>
                    </Col>
                  </Row>
                  <Row
                    hidden={!obsIsOpen}
                    style={{
                      marginTop: 30,
                      paddingTop: 30,
                      borderTop: "1px solid #d4d4d4",
                    }}
                  >
                    <textarea
                      className="form-control"
                      onChange={e => {
                        setClient({
                          ...client,
                          obs: e.target.value,
                        });

                      }}
                      value={client.obs}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Row>
            <Row className="mt-3">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={11}>
                      <p
                        className="mb-0 d-flex align-items-center"
                        style={{ fontWeight: "bold" }}
                      >
                        <i
                          className="ph-notepad"
                          style={{
                            color: "#FF601A",
                            marginRight: 20,
                            fontSize: 25,
                          }}
                        ></i>{" "}
                        Adicionar Observação de Nota Fiscal:
                      </p>
                      <small
                        className="text-muted"
                        style={{ marginLeft: "45px" }}
                      >
                        Adicionar dados do recebedor
                      </small>
                    </Col>
                    <Col md={1}>
                      <a
                        href="#"
                        style={{
                          width: "fit-content",
                          marginLeft: "auto",
                          display: "block",
                        }}
                        onClick={event => {
                          event.preventDefault();
                          setNfIsOpen(!nfIsOpen);
                        }}
                      >
                        {nfIsOpen ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </a>
                    </Col>
                  </Row>
                  <Row
                    hidden={!nfIsOpen}
                    style={{
                      marginTop: 30,
                      paddingTop: 30,
                      borderTop: "1px solid #d4d4d4",
                    }}
                  >
                    <textarea
                      className="form-control"
                      onChange={e => {
                        setClient({
                          ...client,
                          obsNot: e.target.value,
                        });
                      }}
                      value={client.obsNot}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Row>
            {triangularSale && (
              <ShippingData
                shippingZipCode={shippingZipCode}
                setShippingZipCode={setShippingZipCode}
                setShippingAddress={setShippingAddress}
                completedShippingValues={completedShippingValues}
              />
            )}
            <Row className="d-flex justify-content-between" id="frete">
              <Col md={7}>
                <Frete
                  addFrete={addFrete}
                  cepCliente={cepCliente}
                  waitingToChangeCartItems={waitingToChangeCartItems}
                  cepSelected={cepSelected}
                  setCepSelected={setCepSelected}
                  getTheHighestForecastDate={getTheHighestForecastDate}
                  setGetTheHighestForecastDate={
                    setGetTheHighestForecastDate
                  }
                  total={total}
                  itHasForecast={itHasForecast}
                  shippingTotalValue={shippingTotalValue}
                  triangularSale={triangularSale}
                />
                {userProfile?.integrador ? (
                  <div />
                ) : (
                  <Card style={{ padding: 20 }}>
                    <PartnerCompany
                      parceiros={parceiros}
                      pedido={pedido}
                      setParceiros={setParceiros}
                    />
                  </Card>
                )}{" "}
                <Card style={{ padding: 20, minHeight: 226 }}>
                  <Status status={status} />
                </Card>
              </Col>
              <Col md={5}>
                <Resumo
                  valRt={valRt}
                  frete={frete}
                  saldo={saldo}
                  pedido={pedido}
                  changePedidoEmitido={changePedidoEmitido}
                  changeType={changeType}
                  formVisible={formVisible}
                  token={token}
                  setLoadingProspect={setLoadingProspect}
                  cepSelected={cepSelected}
                  getTheHighestForecastDate={getTheHighestForecastDate}
                  total={total}
                  discountValue={discountValue}
                  setFutureSaleValue={setFutureSaleValue}
                  triangularSale={triangularSale}
                  setTriangularSale={setTriangularSale}
                  shippingZipCode={shippingZipCode}
                  completedShippingValues={completedShippingValues}
                  largeAccountCode={largeAccountCode}
                  shippingTotalValue={shippingTotalValue}
                />
              </Col>
            </Row>
            <ControleRemoto />
          </React.Fragment>
        ) : (
          <PedidoEmitido
            changePedidoEmitido={changePedidoEmitido}
            type={type}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="page-content neworder solar">
        <Container>{renderView()}</Container>
      </div>
      <ModalAlert
        openModal={openModal}
        setOpenModal={setOpenModal}
        footer
        modalAlert
        header="Termo de Acordo"
        textButton="Estou ciente!"
        onClick={handleClickModalConfirm}
      >
        O dimensionamento assim como a escolha de seus componentes é de
        responsabilidade exclusiva da empresa integradora, e deve ser conduzida
        por corpo técnico responsável.
      </ModalAlert>
    </>
  );
};

export default SolarForaKit;