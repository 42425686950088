import React from "react";
import { useSelector } from "react-redux";
import { useSolar } from "../../../hooks";

const ButtonPedidoRemessa = ({ onClose }) => {
  const { order } = useSelector(state => ({
    order: state.Order.orderActive.order,
  }));
  const isSolar = useSolar();
  const futureSaleCtpoper = ['02', '03', '52', '53'];

  return (
    <div>
      {(isSolar && order?.XPEDCLI && futureSaleCtpoper.includes(order.items_orcamento[0].CTPOPER)) ? (
        order.pedidoAtrelado ? (
          <a
            className="btn btn-rounded bg-white shadow me-3 px-3 botoes-itens"
            target="_blank"
            rel="noreferrer"
            href={`/solar/pedidos/${String(order.pedidoAtrelado).padStart(
              6,
              "0"
            )}/${order.pedidoAtreladoFilial}/order`}
          >
            <i className="ph-package icones-status"></i>
            {order.items_orcamento[0].CTPOPER === "02" ||
              order.items_orcamento[0].CTPOPER === "52"
              ? "Visualizar Pedido Futuro"
              : "Visualizar Pedido Remessa"}
          </a>
        ) : (
          <a
            className="btn btn-rounded bg-white shadow me-3 px-3 botoes-itens"
            onClick={onClose}
          >
            <i className="ph-package icones-status"></i>
            Gerar Pedido Remessa
          </a>
        )
      ) : null}
    </div>
  );
};

export default ButtonPedidoRemessa;
