import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CardBody,
  Col,
  Row,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner
} from "reactstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { getVendedor as getVendedorApi } from "../../api/index";
import {
  getNewOrder,
  changeGroup,
  changeCategory,
  changePagto,
  getOrderProducts,
  zerarCarrinho,
  atualizaCarrinho,
  ocultarRT,
  setPagtoSelected,
  setFilterPedido,
  setTabela,
  updateZipcodeModalIsOpen,
} from "../../store/actions";
import { mask, returnLogo, scrollToDiv } from "../../utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CaretDown, CaretRight, X, XCircle } from "phosphor-react";
import SelectSearch from "../../components/SelectSerach";
import ModalUpdateZipcode from "../UpdateZipcode";
import "./newOrder.css";
import { Alert } from "@mui/material";
import { useDataLayer } from "../../hooks/DataLayerHooks";
import { Form } from "react-bootstrap";

const OrderFilter = ({
  pagamento,
  setPagamento,
  setAwaitModfyCart,
  pedido,
  cartBranchSameCustomerUfTable,
  ufValidate,
  history,
  setRemoveFromCartState,
  removeFromCartState,
}) => {
  const { id } = useParams();
  const catSelector = createRef();
  const gruSelector = createRef();
  const dispatch = useDispatch();
  const { newOrder, order, ocultarRt, filterPedido, parceiros, tabela, defaultZipcode } =
    useSelector(state => ({
      newOrder: state.Order.newOrder.newOrder,
      order: state.Order.orderActive.order,
      ocultarRt: state.Order.newOrder.newOrder.filters.ocultarRt,
      filterPedido: state.Order.newOrder.newOrder.filters.filterPedido,
      parceiros: state.Order.newOrder.newOrder.filters.parceiros,
      tabela: state.Order.newOrder.newOrder.filters.tabela,
      defaultZipcode: state.Layout.defaultZipcode
    }));

  const { pushToDataLayer } = useDataLayer();

  const [form, setForm] = useState({
    capacidade: "",
    categoria: "",
    condpagto: "",
    fabricante: "",
    grupos: "",
    ciclo: "",
    tabela_preco: "",
    voltagem: "",
    estoque: "-1000",
    descricao: "",
    bitolaliq: "",
    bitolasuc: "",
    codfabric: "",
    cepentrega: defaultZipcode?.cep,
  });
  const [filters, setFilters] = useState({
    vendedores: [{ codigo: "", label: "" }],
    tabela: [],
    condPagto: [],
    grupos: [],
    categorias: [],
    capacidade: [],
    voltagem: [],
    fabricantes: [],
  });
  const [initial, setInitial] = useState([]);

  const [isLoading] = useState(false);
  const [capVisible, setCapVisible] = useState(false);
  const [capSelected, setCapSelected] = useState([]);
  const [fabSelected, setFabSelected] = useState([]);
  const [cicSelected, setCicSelected] = useState([]);
  const [volSelected, setVolSelected] = useState([]);

  const [grupo, setGrupo] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [count, setCount] = useState(0);
  const [varEscolha, setVarEscolha] = useState([]);
  const [arrayFab, setArrayFab] = useState();
  const [fabVisible, setFabVisible] = useState("first");
  const [serachAdvanced, setSeachAdvanced] = useState(false);

  const fabs = [
    { CODIGO: "LG", DESCR: "LG" },
    { CODIGO: "DA", DESCR: "DAIKIN" },
    { CODIGO: "TC", DESCR: "TCL" },
    { CODIGO: "GR", DESCR: "GREE" },
    { CODIGO: "EL", DESCR: "ELGIN" },
    { CODIGO: "MI", DESCR: "MIDEA" },
    { CODIGO: "CA", DESCR: "CARRIER" },
    { CODIGO: "FU", DESCR: "FUJITSU" },
    { CODIGO: "HI", DESCR: "HITACHI" },
    { CODIGO: "SM", DESCR: "SPRINGER MIDEA" },
    { CODIGO: "SP", DESCR: "SPRINGER" },
    { CODIGO: "TR", DESCR: "TRANE" },
  ];
  const [scrollButton, setScrollButton] = useState(null);
  const filterBy = () => true;

  const [isLoadingVendedores, setIsLoadingVendedores] = useState(false);

  const vendedorComRt = newOrder.comRt == '1';

  const changeVendedores = inputValue => {
    setIsLoadingVendedores(true);
    setFilters({ ...filters, vendedores: [] });

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];
        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setFilters({ ...filters, vendedores: data });
        setIsLoadingVendedores(false);
      })
      .catch(console.log);
  };

  function getProducts(e) {
    if (form?.tabela_preco === "" || form?.condpagto === "") {
      toast.error("Favor preencher Tabela/Pagamento", { theme: "colored" });
    } else if (
      form?.grupos === "" &&
      form?.categoria === "" &&
      form?.capacidade === ""
    ) {
      toast.error(
        "Favor selecionar ao menos um dos seguintes filtros: Grupo, Categora ou Capacidade",
        { theme: "colored" }
      );
    } else {
      e.preventDefault();

      //verifica se esta selecionado quente e frio, caso esteja ele envia o ciclo com string vazia
      var ciclo = "";
      if (form?.ciclo?.length == 3 || form?.ciclo?.length == "") {
        ciclo = "";
      } else {
        ciclo = cicSelected[0]?.id;
      }

      pushToDataLayer(
        'select_item',
        {
          ecommerce: {
            currency: 'BRL',
            items: {
              item_grupo: form?.grupos,
              item_categoria: form?.categoria,
              item_fabricante: form?.fabricante?.split(','),
              item_capacidade: form?.capacidade?.split(',')
            }
          }
        },
        { clearEcommerce: true }
      );

      dispatch(getOrderProducts({ ...form, ciclo }));

      setScrollButton(state => !state);
    }
  }

  useEffect(() => {
    if (document && window && scrollButton != null) {
      scrollToDiv("sentinel", 200);
    }
  }, [scrollButton]);

  function clearCheckboxes() {
    let capacidade = document.getElementsByName("capacidade");
    for (let cap of capacidade) {
      cap.checked = false;
    }
    let ciclo = document.getElementsByName("ciclo");
    for (let cic of ciclo) {
      cic.checked = false;
    }
    let voltagem = document.getElementsByName("voltagem");
    for (let vol of voltagem) {
      vol.checked = false;
    }
    let fabricante = document.getElementsByName("fabricante");
    for (let fab of fabricante) {
      fab.checked = false;
    }
    form.capacidade = "";
    form.fabricante = "";
    form.ciclo = "";
    form.voltagem = "";

    setCapSelected([]);
    setCicSelected([]);
    setFabSelected([]);
    setVolSelected([]);
  }

  useEffect(() => {
    if (
      !(
        newOrder.tabela &&
        newOrder.condPagto &&
        newOrder.grupos &&
        newOrder.categorias
      )
    )
      dispatch(getNewOrder());
  }, []);

  useEffect(() => {
    if (newOrder) {
      let tab = [];
      Object.keys(newOrder.tabela || {}).map(function (key) {
        tab.push({ codigo: key, label: newOrder.tabela[key].trim() });
      });

      let pag = [];
      let pagto = Object.values(newOrder.condPagto || []);
      let pag_keys = Object.keys(newOrder.condPagto || []);

      for (let i = pagto.length; i >= 0; i--) {
        Object.keys(pagto[i] || {}).map(function (key) {
          pag.push({
            codigo: key,
            label: pagto[i][key].name?.trim(),
            type: pag_keys[i],
          });
        });
      }

      let gru = [];
      Object.values(newOrder.grupos || {}).map(value => {
        gru.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cat = [];
      Object.values(newOrder.categorias || []).map(value => {
        cat.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cap = Object.values(newOrder.capacidade || []);
      let volt = Object.values(newOrder.voltagem || []);
      let fab = Object.values(newOrder.fabricantes || []);
      setFilters({
        ...filters,
        tabela: tab,
        condPagto: pag,
        grupos: gru,
        categorias: cat,
        capacidade: cap,
        voltagem: volt,
        fabricantes: fab,
      });

      if (count <= 5) {
        setInitial({
          tabela: tab,
          condPagto: pag,
          grupos: gru,
          categorias: cat,
          capacidade: cap,
          voltagem: volt,
          fabricantes: fab,
        });

        if (id && order) {
          if (tabela.length < 1 && tab[0]?.codigo) {
            let result = tab.find(i => i.codigo == order.TabelaPreco);
            if (result) dispatch(setTabela([result]));
          }

          if (pagamento.length < 1 && pag[0]?.codigo) {
            let result = pag.find(i => i.codigo == order.CondicaoPagamento);
            if (result) setPagamento([result]);
          }
        } else {
          if (tabela.length < 1 && tab[0]?.codigo) {
            let result = tab.find(i => i.codigo == newOrder.tabelaInicial);
            if (result) dispatch(setTabela([result]));
          }

          if (pagamento.length < 1 && pag[0]?.codigo) {
            let result = pag.find(i => i.codigo == "500");
            if (result) setPagamento([result]);
          }
        }

        if (grupo.length < 1 && gru[0]?.codigo) {
          let result = gru.find(i => i.codigo == "INV");
          if (result) setGrupo([result]);
        }

        if (categoria.length < 1 && cat[0]?.codigo) {
          let result = cat.find(i => i.codigo == "HIW");
          if (result) setCategoria([result]);
        }
        setForm({
          ...form,
          tabela_preco: newOrder.tabelaInicial,
          condpagto: "500",
          grupos: "INV",
          categoria: "HIW",
        });
      }

      setCount(count + 1);
    }
  }, [newOrder]);

  useEffect(() => {
    let table = "";
    if (tabela.length > 0) {
      table = tabela[0].codigo;
      dispatch(changePagto(table));
    }
    setForm({ ...form, tabela_preco: table });
  }, [tabela]);

  useEffect(() => {
    let pagto = "";
    if (pagamento.length > 0) {
      pagto = pagamento[0]?.codigo || "";
      pedido.filters.Nomecondpagto = pagamento[0]?.label || "";
      pedido.filters.condpagto = pagamento[0]?.codigo || "";
      pedido.filters.Nometabela_preco = tabela[0]?.label || "";
      pedido.filters.tabela_preco = tabela[0]?.codigo || "";
      pedido.filters.vendedor2 = parceiros[0]?.codigo || "";
      dispatch(
        setFilterPedido({
          ...filterPedido,
          Nomecondpagto: pagamento[0]?.label || "",
          condpagto: pagamento[0]?.codigo || "",
          Nometabela_preco: tabela[0]?.label || "",
          tabela_preco: tabela[0]?.codigo || "",
          vendedor2: parceiros[0]?.codigo || "",
        })
      );
    }
    setForm({ ...form, condpagto: pagto });

    dispatch(setPagtoSelected(pagamento));
  }, [pagamento, parceiros, history?.location?.pathname]);

  useEffect(() => {
    let gru = "";
    if (grupo.length > 0) {
      gru = grupo[0].codigo;
      if (count > 6) {
        setCategoria([]);
      }
      setFilters({
        ...filters,
        capacidade: initial.capacidade,
      });
      dispatch(changeGroup(gru));
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
          categorias: initial.categorias,
        });
      }
    }
    // if(catSelector.current)
    /* catSelector.current.clear(); */
    clearCheckboxes();
    setForm({
      ...form,
      grupos: gru,
      categoria: "",
    });
  }, [grupo]);

  useEffect(() => {
    let cat = "";
    if (categoria.length > 0) {
      cat = categoria[0].codigo;
      if (grupo[0] && grupo[0].codigo) {
        let paramns = {
          group: grupo[0].codigo,
          category: cat,
        };

        dispatch(changeCategory(paramns));
      } else {
        setCategoria([]);
      }
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
        });
      }
    }
    clearCheckboxes();
    setForm({ ...form, categoria: cat });
  }, [categoria]);
  //manter a ordem dos fabricantes ao clicar em ver todas as opçoes
  const formatDataArray = () => {
    fabs.map(item => {
      filters.fabricantes.map((itemTwo, index) => {
        if (item.CODIGO == itemTwo.CODIGO) {
          filters.fabricantes.splice(index, 1);
        }
      });
    });

    const concat = fabs.concat(filters.fabricantes);

    setArrayFab(concat);
  };

  if (!pagamento || pagamento.length === 0) {
    if (filters && filters.condPagto && filters.condPagto.length > 0) {
      pedido.filters.Nomecondpagto = pagamento[0]?.label || "";
      pedido.filters.condpagto = pagamento[0]?.codigo || "";
    }
  }

  if (!tabela || tabela.length === 0) {
    if (filters && filters.tabela && filters.tabela.length > 0) {
      pedido.filters.Nometabela_preco = tabela[0]?.label || "";
      pedido.filters.tabela_preco = tabela[0]?.codigo || "";
    }
  }

  useEffect(() => {
    if (fabVisible == "first") {
      setArrayFab(fabs);
    } else {
      formatDataArray();
    }
  }, [fabVisible]);

  useEffect(() => {
    if (defaultZipcode?.cep) {
      setForm({
        ...form,
        cepentrega: defaultZipcode.cep
      });
    }

    dispatch(updateZipcodeModalIsOpen(!defaultZipcode));
  }, [defaultZipcode]);

  useEffect(() => {
    if (newOrder?.condPagto && filters?.condPagto?.length > 0) {
      let pag = [];
      let pagto = Object.values(newOrder.condPagto || []);
      let pag_keys = Object.keys(newOrder.condPagto || []);

      for (let i = pagto.length; i >= 0; i--) {
        Object.keys(pagto[i] || {}).map(function (key) {
          pag.push({
            codigo: key,
            label: pagto[i][key].name?.trim(),
            type: pag_keys[i],
          });
        });
      }

      let result = pag.find(i => i.codigo == "500") > 0 ? pag.find(i => i.codigo == "500") : pag[0];
      const selectedOption = [result]; 
      if (newOrder.addedItems.length > 0) {
        if (confirm) {
          setPagamento(selectedOption);
          toast.info(
            `Ao atualizar a condição de pagamento, os valores dos produtos são atualizados e podem sofrer alterações.`,
            { theme: 'colored' }
          );
          dispatch(
            atualizaCarrinho(
              {
                pgto: selectedOption,
                tabela,
                setAwaitModfyCart,
              },
              pedido
            )
          );
        } else {
          setPagamento(pagamento);
        }
      } else {
        setPagamento(selectedOption);
        dispatch(zerarCarrinho());
      }
    }
  }, [newOrder?.condPagto]);

  return (
    <Row id="filter">
      <Col>
        <Card>
          {!defaultZipcode && (
            <div className="disable-container">
              <Alert style={{ width: "fit-content" }} severity="info">
                Preencher o frete acima para prosseguir
              </Alert>
            </div>
          )}
          <CardBody className="filter-order">
            <Row>
              {vendedorComRt && (
                <Col md={2} className="ml-auto">
                  <FormGroup check className="d-flex justify-content-end">
                    <Input
                      type="checkbox"
                      checked={ocultarRt}
                      className="ocultar-rt"
                      onChange={() => dispatch(ocultarRT(!ocultarRt))}
                    />
                    <Label check className=" ocultar">
                      Ocultar valor RT
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="mt-4">
              <Col md={5}>
                <FormGroup>
                  <Label className="label-titulo">Tabela:</Label>
                  <Typeahead
                    clearButton
                    id="select-tabela"
                    labelKey="label"
                    onFocus={() => {
                      dispatch(setTabela([]));
                      setVarEscolha(tabela);
                    }}
                    onBlur={e => {
                      if (tabela.length < 1) {
                        dispatch(setTabela(varEscolha));
                      }
                    }}
                    onChange={table => {
                      dispatch(setTabela(table));
                      /* cartBranchSameCustomerUfTable(ufValidate, table); */
                    }}
                    options={filters.tabela}
                    placeholder="Escolha a tabela de preço:"
                    selected={tabela}
                  />
                </FormGroup>
              </Col>

              <Col md={5}>
                <FormGroup>
                  <Label className="label-titulo">Pagamento:</Label>
                  <Form.Control
                    as="select"
                    value={pagamento[0]?.label || ''} 
                    onChange={e => {
                      const selectedOption = filters?.condPagto.filter(option => option.label === e.target.value);
                      setRemoveFromCartState(true);

                      if (newOrder.addedItems.length > 0) {
                        if (confirm) {
                          setPagamento(selectedOption);
                          toast.info(
                            `Ao atualizar a condição de pagamento, os valores dos produtos são atualizados e podem sofrer alterações.`,
                            { theme: 'colored' }
                          );
                          dispatch(
                            atualizaCarrinho(
                              {
                                pgto: selectedOption,
                                tabela,
                                setAwaitModfyCart,
                              },
                              pedido
                            )
                          );
                        } else {
                          setPagamento(pagamento);
                        }
                      } else {
                        setPagamento(selectedOption);
                        dispatch(zerarCarrinho());
                      }
                    }}
                  >
                    <optgroup label="Pagamento Online">
                      {filters?.condPagto
                        ?.filter(option => option.type === 'Pagamento online')
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map(option => (
                          <option key={option.codigo} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                    </optgroup>

                    <optgroup className="mt-2" label="Pagamento Offline - Precisa de De Acordo assinado">
                      {filters?.condPagto
                        ?.filter(option => option.type === 'Pagamento offline / a prazo')
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map(option => (
                          <option key={option.codigo} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                    </optgroup>
                  </Form.Control>
                </FormGroup>
              </Col>


            </Row>
            <hr className="linha" />
            <Row>
              <Col md={6}>
                <h4 className="subtitulo">Dados do ambiente:</h4>
              </Col>
              <Col md={6}>
                <Button
                  color="light"
                  style={{
                    color: "#2D567B",
                    backgroundColor: "#FFF",
                    borderColor: "#F5F5F5",
                    borderWidth: "2px",
                    display: "flex",
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    gruSelector.current.clear();
                    catSelector.current.clear();
                    clearCheckboxes();
                    setForm({
                      ...form,
                      grupos: "",
                      categoria: "",
                      capacidade: "",
                      fabricante: "",
                      ciclo: "",
                      voltagem: "",
                    });
                    setFilters({
                      ...filters,
                      capacidade: initial.capacidade,
                      categorias: initial.categorias,
                    });
                  }}
                >
                  Limpar Filtros{" "}
                  <X className="ocultar" size={20} weight="bold" />
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label className="label-titulo">Grupo:</Label>
                  <Typeahead
                    clearButton
                    id="select-grupo"
                    labelKey="label"
                    onChange={setGrupo}
                    options={filters.grupos}
                    placeholder="Escolha o grupo de produtos:"
                    ref={gruSelector}
                    selected={grupo}
                    onFocus={() => {
                      setGrupo([]);
                      setVarEscolha(grupo);
                    }}
                    onBlur={() => {
                      if (grupo.length < 1) setGrupo(varEscolha);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="label-titulo">Categoria:</Label>
                  <Typeahead
                    clearButton
                    id="select-categoria"
                    disabled={newOrder.gruposLoading}
                    labelKey={(categoria) => (categoria.label ? categoria.label : categoria.codigo)}
                    onChange={setCategoria}
                    options={filters.categorias}
                    placeholder="Escolha a categoria do produto:"
                    ref={catSelector}
                    selected={categoria}
                    onFocus={() => {
                      setCategoria([]);
                      setVarEscolha(categoria);
                    }}
                    onBlur={() => {
                      if (categoria.length < 1) setCategoria(varEscolha);
                    }}
                  />
                </FormGroup>
              </Col>
              {/* <Col md={4}>
                                <FormGroup>
                                    <Label >
                                        Ambiente:
                                    </Label>
                                    <Input value="" />
                                </FormGroup>
                            </Col> */}
            </Row>
            <Row className="mt-4">
              <Col>
                <FormGroup>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={2}>
                      <Label className="label-titulo mb-0">Capacidade:</Label>
                    </Col>
                    <Col md={8} className="d-flex flex-wrap">
                      {capSelected.map(value => (
                        <p
                          className="px-3 rounded mb-0 cap-selected"
                          key={value.id}
                        >
                          <small>
                            {value.label}
                            <XCircle
                              size={15}
                              weight="bold"
                              onClick={() => {
                                let lab = [...capSelected];
                                let val =
                                  form.capacidade.length > 0
                                    ? form.capacidade.split(",")
                                    : [];
                                const indexLab = lab.indexOf(value);
                                const index = val.indexOf(value.id);
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                                if (index > -1) {
                                  val.splice(index, 1);
                                }

                                const stringElementId = value.id.replace(/^0+/, '');

                                document.getElementById(
                                  `cap-${stringElementId}`
                                ).checked = false;

                                setCapSelected(lab);
                                setForm({ ...form, capacidade: val.join() });
                              }}
                              style={{ marginLeft: 5 }}
                            />
                          </small>
                        </p>
                      ))}
                    </Col>
                  </Row>

                  <Row>
                    {!newOrder?.capacidadeLoading && !newOrder.gruposLoading ? (
                      filters.capacidade.map((value, index) => (
                        <div
                          style={{ width: "20%", marginTop: 25 }}
                          key={index}
                          hidden={index < 10 ? false : !capVisible}
                        >
                          <Input
                            type="checkbox"
                            value={("00" + value.CODIGO).slice(-2)}
                            name="capacidade"
                            aria-label={value.DESCR}
                            id={`cap-${value.CODIGO}`}
                            onClick={e => {
                              let val =
                                form.capacidade.length > 0
                                  ? form.capacidade.split(",")
                                  : [];
                              let lab = [...capSelected];
                              if (e.target.checked) {
                                val.push(e.target.value);
                                lab.push({
                                  id: e.target.value,
                                  label: e.target.ariaLabel,
                                });
                              } else {
                                const index = val.indexOf(e.target.value);
                                const result = lab.find(
                                  i => i.id === e.target.value
                                );
                                const indexLab = lab.indexOf(result);
                                if (index > -1) {
                                  val.splice(index, 1);
                                }
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                              }
                              setForm({ ...form, [e.target.name]: val.join() });
                              setCapSelected(lab);
                            }}
                          />{" "}
                          <Label check className=" ocultar">
                            {value.DESCR}
                          </Label>
                        </div>
                      ))
                    ) : (
                      <div style={{ minHeight: 93 }}>
                        <Spinner />
                      </div>
                    )}
                  </Row>
                  {filters.capacidade.length > 8 ? (
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setCapVisible(!capVisible);
                      }}
                      className="ver-todos"
                    >
                      Ver todas as opções
                      <CaretRight size={18} weight="bold" />
                    </a>
                  ) : (
                    <div />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <div style={{ width: "40%" }}>
                <FormGroup>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={2}>
                      <Label className="label-titulo mb-0">Ciclo:</Label>
                    </Col>
                    <Col md={8} className="d-flex flex-wrap">
                      {cicSelected.map(value => (
                        <p
                          className="px-3 rounded mb-0 cap-selected"
                          key={value.id}
                        >
                          <small>
                            {value.label}
                            <XCircle
                              size={15}
                              weight="bold"
                              onClick={() => {
                                let lab = [...cicSelected];
                                let val =
                                  form.ciclo.length > 0
                                    ? form.ciclo.split(",")
                                    : [];
                                const indexLab = lab.indexOf(value);
                                const index = val.indexOf(value.id);
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                                if (index > -1) {
                                  val.splice(index, 1);
                                }

                                document.getElementById(
                                  `cic-${value.id}`
                                ).checked = false;
                                setCicSelected(lab);

                                setForm({ ...form, ciclo: val.join() });
                              }}
                              style={{ marginLeft: 5 }}
                            />
                          </small>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="ciclo"
                        aria-label="Frio"
                        value="F"
                        id={`cic-F`}
                        onClick={e => {
                          let val =
                            form?.ciclo?.length > 0
                              ? form.ciclo.split(",")
                              : [];
                          let lab = [...cicSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }

                          setForm({ ...form, [e.target.name]: val.join() });
                          setCicSelected(lab);
                        }}
                      />{" "}
                      <Label check className="ocultar">
                        Frio
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="ciclo"
                        aria-label="Quente"
                        value="Q"
                        id={`cic-Q`}
                        onClick={e => {
                          let val =
                            form.ciclo.length > 0 ? form.ciclo.split(",") : [];
                          let lab = [...cicSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setCicSelected(lab);
                        }}
                      />{" "}
                      <Label check className="ocultar">
                        Quente
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
              <div style={{ width: "40%" }}>
                <FormGroup>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={3}>
                      <Label className="label-titulo mb-0">Voltagem:</Label>
                    </Col>
                    <Col md={8} className="d-flex flex-wrap">
                      {volSelected.map(value => (
                        <p
                          className="px-3 rounded mb-0 cap-selected"
                          key={value.id}
                        >
                          <small>
                            {value.label}
                            <XCircle
                              size={15}
                              weight="bold"
                              onClick={() => {
                                let lab = [...volSelected];
                                let val =
                                  form.voltagem.length > 0
                                    ? form.voltagem.split(",")
                                    : [];
                                const indexLab = lab.indexOf(value);
                                const index = val.indexOf(value.id);
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                                if (index > -1) {
                                  val.splice(index, 1);
                                }

                                document.getElementById(
                                  `vol-${value.id}`
                                ).checked = false;
                                setVolSelected(lab);
                                setForm({ ...form, voltagem: val.join() });
                              }}
                              style={{ marginLeft: 5 }}
                            />
                          </small>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={1}
                        aria-label="110/MONOFASICO"
                        id={`vol-1`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className=" ocultar">
                        110/MONOFASICO
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={2}
                        aria-label="220/MONOFASICO"
                        id={`vol-2`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className=" ocultar">
                        220/MONOFASICO
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={3}
                        aria-label="220/TRIFASICO"
                        id={`vol-3`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className=" ocultar">
                        220/TRIFASICO
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={4}
                        aria-label="380/TRIFASICO"
                        id={`vol-4`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className=" ocultar">
                        380/TRIFASICO
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </Row>
            <Row className="mt-4">
              <Col>
                <FormGroup>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={2}>
                      <Label className="label-titulo mb-0">Fabricante:</Label>
                    </Col>
                    <Col md={8} className="d-flex flex-wrap">
                      {fabSelected.map(value => (
                        <p
                          className="px-3 rounded mb-0 cap-selected"
                          key={value.id}
                        >
                          <small>
                            {value.label}
                            <XCircle
                              size={15}
                              weight="bold"
                              onClick={() => {
                                let lab = [...fabSelected];
                                let val =
                                  form.fabricante.length > 0
                                    ? form.fabricante.split(",")
                                    : [];
                                const indexLab = lab.indexOf(value);
                                const index = val.indexOf(value.id);
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                                if (index > -1) {
                                  val.splice(index, 1);
                                }

                                document.getElementById(
                                  `fab-${value.id}`
                                ).checked = false;
                                setFabSelected(lab);
                                setForm({ ...form, fabricante: val.join() });
                              }}
                              style={{ marginLeft: 5 }}
                            />
                          </small>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    {arrayFab?.map((value, index) => (
                      <Col md={2} key={index}>
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            name="fabricante"
                            value={value.CODIGO.trim()}
                            aria-label={value.DESCR.trim()}
                            id={`fab-${value.CODIGO.trim()}`}
                            onClick={e => {
                              let val =
                                form.fabricante.length > 0
                                  ? form.fabricante.split(",")
                                  : [];
                              let lab = [...fabSelected];
                              if (e.target.checked) {
                                val.push(e.target.value);
                                lab.push({
                                  id: e.target.value,
                                  label: e.target.ariaLabel,
                                });
                              } else {
                                const index = val.indexOf(e.target.value);
                                const result = lab.find(
                                  i => i.id === e.target.value
                                );
                                const indexLab = lab.indexOf(result);

                                if (index > -1) {
                                  val.splice(index, 1);
                                }
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                              }
                              setForm({
                                ...form,
                                [e.target.name]: val.join(),
                              });
                              setFabSelected(lab);
                            }}
                          />
                          <div className="box">
                            <img src={returnLogo(value.CODIGO)} />
                            <p className="text-muted">{value.DESCR}</p>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    setFabVisible(state => (state == "all" ? "first" : "all"));
                  }}
                  className="ver-todos"
                >
                  Ver todas as opções
                  <CaretRight size={18} weight="bold" />
                </a>
              </Col>
            </Row>
            <Label
              className="label-titulo mb-4 search-advanced"
              onClick={() => setSeachAdvanced(state => !state)}
            >
              Buscar Avançada{" "}
              <CaretDown
                style={{
                  transform: serachAdvanced ? "rotate(180deg)" : "rotate(0deg)",
                  marginLeft: 10,
                }}
                size={20}
                weight="bold"
              />
            </Label>
            <Row
              style={{
                height: serachAdvanced ? 160 : 0,
                overflow: "hidden",
                transition: "height 0.5s ease",
              }}
            >
              <Row style={{ marginBottom: 10 }}>
                <Col md={4}>
                  <Label>Descrição</Label>
                  <Input
                    className="titulo-input"
                    placeholder="Descrição"
                    onChange={e =>
                      setForm(state => {
                        return { ...state, descricao: e.target.value };
                      })
                    }
                  />
                </Col>{" "}
                <Col md={4}>
                  <Label>Sku</Label>
                  <Input
                    className="titulo-input"
                    placeholder="SKUs separados por vírgula"
                    onChange={e =>
                      setForm(state => {
                        return { ...state, sku: e.target.value };
                      })
                    }
                  />
                </Col>
                <Col md={4}>
                  <Label>Código Fabricante</Label>
                  <Input
                    className="titulo-input"
                    placeholder="Código Fabricante"
                    onChange={e =>
                      setForm(state => {
                        return { ...state, codfabric: e.target.value };
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label>Bitola Liquida</Label>
                  <select
                    onChange={e =>
                      setForm(state => {
                        return { ...state, bitolaliq: e.target.value };
                      })
                    }
                    className="form-select form-control"
                  >
                    <option value="">Todos</option>
                    <option value="0,25">0,25</option>
                    <option value="0,75">0,75</option>
                    <option value="00">00</option>
                    <option value="1,4">1,4</option>
                    <option value="1/2">1/2</option>
                    <option value="1/4">1/4</option>
                    <option value="1/4 Pol">1/4 Pol</option>
                    <option value="1/4 e 1/2">1/4 e 1/2</option>
                    <option value="1/4 e 3/8">1/4 e 3/8</option>
                    <option value="1/4 e 5/8">1/4 e 5/8</option>
                    <option value="1/4 pol">1/4 pol</option>
                    <option value="1/4">1/4"</option>
                    <option value="1/4">1/4" - 3/8"</option>
                    <option value="1/8">1/8</option>
                    <option value="11">11</option>
                    <option value="17,11">17,11</option>
                    <option value="3,8">3,8</option>
                    <option value="3/4">3/4</option>
                    <option value="3/4 até 10m">3/4 até 10m</option>
                    <option value="3/8">3/8</option>
                    <option value="3/8">3/8</option>
                    <option value="3/8 e 5/8">3/8 e 5/8</option>
                    <option value="3/8 pol">3/8 pol</option>
                    <option value="3/8">3/8"</option>
                    <option value="5/8 até 15m">5/8 até 15m</option>
                    <option value="6,35">6,35</option>
                    <option value="6,35 (1/4)">6,35 (1/4)</option>
                    <option value="6,35mm">6,35mm</option>
                    <option value="6,4(1/4)">6,4(1/4)</option>
                    <option value="7,2">7,2</option>
                    <option value="7/8">7/8</option>
                    <option value="9,52">9,52</option>
                  </select>
                </Col>
                <Col md={6}>
                  <Label>Bitola Sucção</Label>
                  <select
                    onChange={e =>
                      setForm(state => {
                        return { ...state, bitolasuc: e.target.value };
                      })
                    }
                    name="tabela_preco"
                    className="form-select form-control"
                  >
                    <option value="">Todos</option>
                    <option value="0,375">0,375</option>
                    <option value="0,5">0,5</option>
                    <option value="00">00</option>
                    <option value="1,2">1,2</option>
                    <option value="1/2">1/2</option>
                    <option value="1/2 até 15 m">1/2 até 15 m</option>
                    <option value="1/2 até 15m">1/2 até 15m</option>
                    <option value="1/2 pol">1/2 pol</option>
                    <option value="1/2">1/2"</option>
                    <option value="1/4">1/4</option>
                    <option value="1/4 e 1/2">1/4 e 1/2</option>
                    <option value="1/4 e 3/8">1/4 e 3/8</option>
                    <option value="1/4 e 5/8">1/4 e 5/8</option>
                    <option value="1/8">1/8</option>
                    <option value="12,7">12,7</option>
                    <option value="12,7 (1/2)">12,7 (1/2)</option>
                    <option value="15,87">15,87</option>
                    <option value="15,88 (5/8">15,88 (5/8</option>
                    <option value="15,88 (5/8)">15,88 (5/8)</option>
                    <option value="3,4">3,4</option>
                    <option value="3/4">3/4</option>
                    <option value="3/4   7/8">3/4 7/8</option>
                    <option value="3/4  5/8">3/4 5/8</option>
                    <option value="3/4 5/8 7/8">3/4 5/8 7/8</option>
                    <option value="3/4 até 10m">3/4 até 10m</option>
                    <option value="3/4 até 20m">3/4 até 20m</option>
                    <option value="3/4 até 30m">3/4 até 30m</option>
                    <option value="3/4 até 7,5">3/4 até 7,5</option>
                    <option value="3/7">3/7</option>
                    <option value="3/8">3/8</option>
                    <option value="3/8 Pol">3/8 Pol</option>
                    <option value="3/8 e 5/8">3/8 e 5/8</option>
                    <option value="3/8 pol">3/8 pol</option>
                    <option value="3/8">3/8"</option>
                    <option value="5/3">5/3</option>
                    <option value="5/8">5/8</option>
                    <option value="5/8  3/4">5/8 3/4</option>
                    <option value="5/8 ate 10m">5/8 ate 10m</option>
                    <option value="5/8 até 10m">5/8 até 10m</option>
                    <option value="5/8 até 7,5">5/8 até 7,5</option>
                    <option value="5/8 pol">5/8 pol</option>
                    <option value="5/8">5/8"</option>
                    <option value="7/8">7/8</option>
                    <option value="7/8 até 15m">7/8 até 15m</option>
                    <option value="7/8 até 30m">7/8 até 30m</option>
                    <option value="7/8">7/8"</option>
                    <option value="9,52 (3/8)">9,52 (3/8)</option>
                    <option value="9,53">9,53</option>
                    <option value="9,53mm">9,53mm</option>
                  </select>
                </Col>
              </Row>
            </Row>
            <Button
              onClick={e => {
                getProducts(e);
                setRemoveFromCartState(false);
              }}
              className="mt-4 buscar-produto"
              disabled={newOrder.orderProducts?.isLoading}
            >
              BUSCAR PRODUTOS
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default OrderFilter;
