import { apiClient2 } from "./apiCore2";

// utils
import { objectToUrlParams } from "../utils/index";
import { apiClient } from "./apiCore";

const getCustomersList = (filters = {}) => {
  const { cpfCnpj, nome, codigo, page } = filters;

  let baseUrl = "/api/clientes";

  if (cpfCnpj || nome || codigo || page) {

    baseUrl += '?';

    if (cpfCnpj && cpfCnpj != '') {
      baseUrl += addParam(baseUrl, 'CPFCNPJ', cpfCnpj);
    }

    if (nome && nome != '') {
      baseUrl += addParam(baseUrl, 'NOME', nome);
    }

    if (codigo && codigo != '') {
      baseUrl += addParam(baseUrl, 'CODIGO', codigo);
    }

    if (page && page != '') {
      baseUrl += addParam(baseUrl, 'page', page);
    }
  }

  return apiClient.get(`${baseUrl}`);
};

const addParam = (baseUrl, param, value) => {
  return `${!baseUrl.endsWith('?') ? '&' : ''}${param}=${value}`;
};

const getCustomerDetails = customerId => {
  const baseUrl = `api/cliente`;
  return apiClient.get(`${baseUrl}/${customerId}/editar`);
};

const saveCustomerDetails = (customerId, data) => {
  const baseUrl = `/api/cliente`;
  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  const params = objectToUrlParams(data);
  return apiClient.create(`${baseUrl}/${customerId}/editar`, params, config);
};


export const createCustomer = data => {
  const baseUrl = "/api/create-client";
  return apiClient.create(`${baseUrl}`, data);
};

const getAddressByZipcode = zipcode => {
  const baseUrl = `/api/consulta/cep`;
  return apiClient.create(`${baseUrl}`, zipcode);
};

const getCustomer = ({ cpfcnpj }) => {

  const baseUrl = `/api/clientes?CPFCNPJ=${cpfcnpj}`;
  return apiClient.get(`${baseUrl}`);
}

export const getMultiTelephones = (id) => {
  const baseUrl = `/api/cliente/${id}/telefone`;
  return apiClient2.get(`${baseUrl}`);
};

export const createOrUpdateMultiTelephones = (data) => {
  const baseUrl = data.rowData.CODIGO
    ? `api/cliente/${data.customerId}/telefone/edit`
    : `api/cliente/${data.customerId}/telefone`;
  return data.rowData.CODIGO ? apiClient.update(baseUrl, data) : apiClient.create(baseUrl, data);
};



export {
  getCustomersList,
  getCustomerDetails,
  saveCustomerDetails,
  getAddressByZipcode,
  getCustomer,
};
