import axios from "axios";
import config from "../config";
import { APIClient } from "./apiClient";
import { getAuthenticatedUser } from "../helpers/firebase_helper";
import { toast } from "react-toastify";

//pass new generated access token here
// const token = ""
//apply base url for axios
const API_URL = config.API_URL;
const MAX_RETRY_ATTEMPTS = 3;
let retryCount = 0;

const instance = axios.create();

instance.defaults.baseURL = API_URL;

// axiosApi.defaults.headers.common["Authorization"] = token;
// content type
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.interceptors.response.use(
  response => {
    return response?.data ? response?.data : response;
  },
  async function (error) {
    if (error?.message == "Cancelando solicitação anterior") {
      return;
    }
    if (!error?.response) {
      toast.error(
        "Dispositivo desconectado! Verifique o status da sua internet e tente novamente."
      );
    }

    let message = '';

    const unauthorizatedStatus = 401;

    if (error.response.status == unauthorizatedStatus) {
      if (retryCount < MAX_RETRY_ATTEMPTS) {
        retryCount++;

        const response = await refreshToken(error);
        const data = await instance.request(response.config);
        return data;
      }
    }

    if (!error.response?.data?.error && !error.response?.data?.message) {
      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Maximum number of retry attempts reached";
          console.log(message);
          window.location.pathname = "/login";
          break;
        case 406:
          message = "Não foi possivel consumir o recurso, API retornou 406";
          console.log(message);
          break;
        case 403:
          message = "Não foi possivel consumir o recurso, API retornou 403";
          console.log(message);
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error.message || error;
      }
    } else {
      message = error?.response?.data?.error ? error.response.data.error : error.response.data.message;
    }

    return Promise.reject(message);
  }
);

async function refreshToken(error) {
  return new Promise((resolve, reject) => {
    try {
      const user = getAuthenticatedUser();

      const parameters = {
        method: "POST",
        headers: { "Content-Type": "application / x-www-form-urlencoded" },
        body: JSON.stringify({}),
      };

      const body = {
        grant_type: "refresh_token",
        refresh_token: user?.stsTokenManager?.refreshToken,
      };

      axios
        .post(
          `https://securetoken.googleapis.com/v1/token?key=${user.apiKey}`,
          body,
          parameters
        )
        .then(async res => {
          let data = res.data;
          user.stsTokenManager.refreshToken = data.refresh_token;
          user.stsTokenManager.accessToken = data.access_token;
          user.stsTokenManager.expirationTime =
            Number(new Date().getTime()) + Number(data.expires_in);
          if (user?.stsTokenManager?.expirationTime) {
            localStorage.setItem("_app_auth_user", JSON.stringify(user));
          }
          instance.defaults.headers.common["Authorization"] =
            "Bearer " + data.access_token;
          error.config.headers.Authorization = "Bearer " + data.access_token;
          return resolve(error);
        })
        .catch(err => {
          return reject(err);
        });
    } catch (err) {
      return reject(err);
    }
  });
}

const setAuthorization = token => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
  // instance.defaults.headers.common["Authorization"] = "Bearer " + "";
};

const apiClient = new APIClient(instance);
export { setAuthorization, apiClient, instance };