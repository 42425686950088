import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import AddedItem from "../AddedItem";
import { formato } from "../../../../utils";
import "./style.js";
import { toast } from "react-toastify";
import { Container } from "./style.js";

const Cart = ({
  addValTot,
  valores,
  loadingSearModule,
  setPercentWattpay,
  valorRt,
  addValorRt,
  valArquiteto,
  ocultarRt,
  addValArqu,
  setItensCarrinho,
  itensCarrinho,
  percentCashBackAll,
  setPercentCashBackAll,
  setPercentCashBackAllOnBlur,
  percentCashBackAllOnBlur,
  realPower,
  setRealPower,
  setValTotAndRt,
  valTotAndRt,
  acrescimo,
  changeFormOfPayment,
  addValorRtNoRetention,
  total,
  isMicroInverterSelected,
  itHasForecast
}) => {
  const { newOrder, order, vendedor } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      order: state.Order.orderActive.order,
      vendedor: state.Order.newOrder.newOrder.vendedor,
    };
  });

  const realPowerFormat = Intl.NumberFormat("pt-BR").format(realPower);

  const handleAllCashBack = event => {
    var value = event.target.value;

    if (parseInt(value) < 0 || parseInt(value) > 50) {
      toast.warning(`A % deve estar entre 0% e 50%`, { theme: "colored" });
    } else {
      setPercentCashBackAll(event.target.value);
    }
  };

  useEffect(() => {
    if (order?.PercentualRT) {
      setPercentCashBackAll(order.PercentualRT);
    }
  }, [order]);

  const handleAllCashBackBlur = () => {
    setPercentCashBackAllOnBlur(state => !state);
  };

  useEffect(() => {
    addValTot(valTotAndRt);
  }, [valTotAndRt]);

  useEffect(() => {
    if (changeFormOfPayment) {
      setPercentCashBackAll("0");
    }
  }, [changeFormOfPayment]);

  useEffect(() => {
    if (newOrder?.addedItems?.length > 0) {
      const modules = newOrder?.addedItems?.filter(item =>
        item?.produto[0]?.nome?.includes("MOD.")
          ? item?.produto[0].nome?.includes("MOD.")
          : item?.produto[0].nome?.includes("Modulos")
            ? item?.produto[0].nome?.includes("Modulos")
            : item?.produto[0].nome?.includes("MOD FOTOV")
              ? item?.produto[0].nome?.includes("MOD FOTOV")
              : item?.produto[0].nome?.includes("MOD. FOTOV")
                ? item?.produto[0].nome?.includes("MOD. FOTOV")
                : item?.produto[0].nome?.includes("GCL")
      );
      setRealPower(modules.reduce((total, mod) => total + (mod.produto[0].potencia * mod.produto[0].qtd), 0));
    }
  }, [newOrder?.addedItems]);

  return (
    <Container ItHasForecast={itHasForecast}>
      <Col className="cart">
        <>
          <h4
            className="mb-4"
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#FF601A",
            }}
          >
            Produtos Adicionados
          </h4>

          <Card>
            <CardBody>
              <div style={{ zIndex: 100 }}>
                <Row>
                  <Col
                    md={8}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 20,
                    }}
                  >
                    <span
                      style={{
                        color: "#495057",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                        fontSize: 17,
                      }}
                    >
                      Potência:
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "normal",
                          fontSize: 16,
                        }}
                      >
                        {realPowerFormat}
                      </p>
                    </span>
                  </Col>{" "}
                  <Col md={4}>
                    <div className="product-info" hidden={ocultarRt}>
                      <p
                        style={{
                          marginBottom: 3,
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Adicionar % de CashBack para todos os produtos"
                      >
                        Porcentagem aplicada em todos os produtos
                      </p>
                      <Input
                        className="mb-0 mt-4 pre-venda"
                        style={{ maxWidth: 170 }}
                        value={percentCashBackAll.replace(/[^\d.,]/g, "")}
                        onChange={handleAllCashBack}
                        onBlur={handleAllCashBackBlur}
                        readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{ marginLeft: 30, marginRight: 30, marginTop: 20 }}
                >
                  <Col
                    style={{ padding: 0 }}
                    md={6}
                  >
                    PRODUTO
                  </Col>
                  <Col style={{ padding: 0, textAlign: "center" }} md={2}>
                    DATA SHEET
                  </Col>
                  <Col style={{ padding: 0 }} md={1}>
                    QTDE
                  </Col>
                  <Col style={{ padding: 0 }} md={2}>
                    PREVISÃO DISP.
                  </Col>
                </Row>
              </div>
              <div className="box-cart">
                {newOrder.addedItems?.map((item, k) => (
                  <>
                    <AddedItem
                      key={k}
                      item={item.produto[0]}
                      addValTot={addValTot}
                      valores={valores}
                      loadingSearModule={loadingSearModule}
                      setItensCarrinho={setItensCarrinho}
                      setPercentWattpay={setPercentWattpay}
                      itensCarrinho={itensCarrinho}
                      valArquiteto={valArquiteto}
                      addValArqu={addValArqu}
                      valorRt={valorRt}
                      addValorRt={addValorRt}
                      ocultarRt={ocultarRt}
                      percentCashBackAll={percentCashBackAll}
                      setPercentCashBackAll={setPercentCashBackAll}
                      percentCashBackAllOnBlur={percentCashBackAllOnBlur}
                      setValTotAndRt={setValTotAndRt}
                      acrescimo={acrescimo}
                      changeFormOfPayment={changeFormOfPayment}
                      itHasForecast={itHasForecast}
                      addValorRtNoRetention={addValorRtNoRetention}
                      setRealPower={setRealPower}
                    />
                  </>
                ))}
              </div>
              <p className="mt-2" style={{ color: "#FF601A" }}><bold>**Não é permitido editar os itens do kit, apenas alterar o cashback</bold></p>
              <Card
                style={{
                  borderRadius: "0 0  15px 15px",
                  backgroundColor: "#FFF7F2",
                  zIndex: 100,
                  marginTop: 20,
                }}
              >
                <CardBody>
                  <Row>
                    <Col style={{ color: "#FF601A" }} md={3}>
                      TOTAL DO ORÇAMENTO
                    </Col>
                    {/*     <Col style={{ color: "#FF601A" }} md={2}>
                            -
                          </Col> */}
                    <Col style={{ color: "#FF601A" }} md={3}>
                      -
                    </Col>
                    {/*     <Col style={{ color: "#FF601A" }} md={2}>
                            -
                          </Col> */}{" "}
                    <Col md={3}></Col>
                    <Col style={{ color: "#FF601A" }} md={3}>
                      {total.toLocaleString("pt-br", formato)}
                    </Col>{" "}
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </>
      </Col>
    </Container>
  );
};

export default Cart;
