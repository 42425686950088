import React, { useState, useEffect } from "react";
import { CardBody, Card, Input, FormGroup, Label, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearNewOrder, getFreteValue } from "../../../store/actions";

const Frete = ({
  addFrete,
  waitingToChangeCartItems,
  cepSelected,
  setCepSelected,
  getTheHighestForecastDate,
  setGetTheHighestForecastDate,
  total,
  itHasForecast,
  shippingTotalValue,
  triangularSale,
}) => {
  const dispatch = useDispatch();
  const { frete, newOrder, emissaoPedido } = useSelector(state => ({
    frete: state.Order.newOrder.newOrder.frete,
    newOrder: state.Order.newOrder.newOrder,
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
  }));
  const verifyErrorFreteReload = emissaoPedido?.data?.msg?.includes(
    "Error ao validar frete, digite o CEP de entrega novamente ou entre em contato com o Suporte."
  );
  const address = frete?.address;
  const [valor, setValor] = useState(0);
  const [, setBtnFreteActive] = useState(false);

  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };

  const getGreaterPrediction = () => {
    const productsThatHaveExpectedDelivery = newOrder.addedItems.filter(
      item => item.produto?.[0]?.previsao != null && itHasForecast.some(value => value.produto[0].sku == item.produto[0].sku)
    );

    if (productsThatHaveExpectedDelivery.length === 0) {
      return null;
    }

    const biggestPrediction = productsThatHaveExpectedDelivery.reduce((biggerDate, item) => {
      const predictionDate = item.produto[0].previsao;
      const numericPredictionDate = convertToNumericDate(predictionDate);

      if (biggerDate) {
        const numericBiggerDate = convertToNumericDate(biggerDate);
        return numericPredictionDate > numericBiggerDate ? predictionDate : biggerDate;
      }

      return predictionDate;
    }, productsThatHaveExpectedDelivery[0].produto[0].previsao);

    return biggestPrediction;
  };

  const convertToNumericDate = date => {
    const [dia, mes, ano] = date.split('/').map(Number);
    return ano * 10000 + mes * 100 + dia;
  }

  useEffect(() => {
    if (newOrder.addedItems.length > 1) {
      setGetTheHighestForecastDate(getGreaterPrediction());
    }
  }, [newOrder.addedItems]);

  useEffect(() => {
    dispatch(clearNewOrder());
    setValor(0);
    setBtnFreteActive(false);
    setCepSelected(1000);
  }, []);

  useEffect(() => {
    if (waitingToChangeCartItems) {
      return;
    }

    const value = triangularSale ? Number(shippingTotalValue) : total;

    if (address && value > 0) {
      dispatch(
        getFreteValue(value, "02", "01")
      );
      addFrete({});
      setValor(0);
      setCepSelected(1000);
      let frete = document.getElementsByName("frete");
      for (let fre of frete) {
        fre.checked = false;
      }
    }
  }, [
    address,
    total,
    waitingToChangeCartItems,
    getTheHighestForecastDate,
    verifyErrorFreteReload,
  ]);

  const getDifferenceForecastDays = () => {
    const now = new Date();

    if (!getGreaterPrediction()) {
      return 0;
    }

    const [day, month, year] = getGreaterPrediction().split('/');
    const forecast = new Date(`${year}-${month}-${day}`);

    if (forecast && forecast > now) {
      const differenceInMilliseconds = forecast - now;
      const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      return differenceInDays;
    }

    return 0;
  }

  const daysForProductionAndShipping = 3;

  return (
    <Card>
      <CardBody>
        <h4
          style={{
            fontSize: 23,
            fontWeight: "bold",
            color: "#202833",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Calcular frete e prazo de entrega{" "}

          <span className="text-muted" style={{ fontSize: 14, fontWeight: "normal" }}>
            <small>
              Partindo de: São Paulo
            </small>
          </span>
        </h4>
        <p style={{ margin: "9px 0 0 0" }}>
          CEP:{" "}
          {frete?.address?.zipcode ? (
            <span>{frete?.address?.zipcode}</span>
          ) : (
            <small className="text-muted">Nenhum cep informado</small>
          )}
        </p>
        <p style={{ margin: "9px 0 0 0" }}>
          {frete?.address ? (
            <span>
              {frete.address.address} - {frete.address.city}/
              {frete.address.state}
            </span>
          ) : (
            <span />
          )}
        </p>

        {frete.success ? (
          <div>
            <hr style={{ margin: "8px 0" }} />
            <h4
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#202833",
                marginBottom: 15,
                display: "flex",
                flexDirection: "column",
              }}
            >
              Selecione a modalidade{" "}
            </h4>
            <div
              onChange={e => {
                setValor(e.target.value);
              }}
            >
              {frete?.entrega ? (
                <>
                  {frete.entrega.map((value, index) => (
                    <FormGroup check className="d-flex mt-3" key={index}>
                      <Input
                        type="radio"
                        id={`check-${index}`}
                        value={value.price}
                        name="frete"
                        className="modalidade"
                        onChange={() => {
                          addFrete(value);
                          setCepSelected(index);
                        }}
                      />
                      <Label
                        className={
                          cepSelected == index ? "label-prazo-active" : ""
                        }
                        htmlFor={`check-${index}`}
                        style={{ marginLeft: 10, fontWeight: 400 }}
                      >
                        {value.description}{" "}
                        {value.usado_percentual > 0 ? (
                          <span style={{ margin: "0 5px" }}>%</span>
                        ) : (
                          ""
                        )}{" "}
                        - Prazo até {getDifferenceForecastDays() + daysForProductionAndShipping + value.delivery_delay} dia(s) -{" "}
                        {Number(value.price).toLocaleString("pt-br", formato)}
                      </Label>
                    </FormGroup>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {frete.loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 121,
            }}
          >
            <Spinner size="sm" />
            <span style={{ color: "#555b62" }}>Buscando fretes</span>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Frete;
