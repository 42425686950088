import { apiClient2 } from "./apiCore2";
import { apiClient } from "./apiCore";

import axios from "axios";

const getProfileDetails = () => {
  const baseUrl = "api/users/details";
  return apiClient.get(`${baseUrl}`);
};

const updateProfileDetails = (profile) => {
  const baseUrl = `api/users/${profile.user.id}`;
  const data = {
    user: {
      firstname: profile.user.firstname || "",
      lastname: profile.user.lastname || ""
    },
    userInfo: {
      cellphone: profile.userInfo.cellphone || "",
      telephone: profile.userInfo.telephone || "",
      gender: profile.userInfo.gender || "",
      birth_date: profile.userInfo.birth_date || "",
      about: profile.userInfo.about || "",
      website: profile.userInfo.website || ""
    },
    userAddress: {
      address: profile.userAddress.address || "",
      number: profile.userAddress.number || "",
      complement: profile.userAddress.complement || "",
      zipcode: profile.userAddress.zipcode || "",
      district: profile.userAddress.district || "",
      city: profile.userAddress.city || "",
      state: profile.userAddress.state || ""
    }
  }
  return apiClient.update(`${baseUrl}`, data);
};

const getCities = (stateCode) => {
  const baseUrl = "/getCities";
  return apiClient2.create(`${baseUrl}`, {
    "state": stateCode
  });
}

export const getCity = (number) => {
  const baseUrl = `api/city/${number}`;
  return apiClient.get(`${baseUrl}`);
}

export const getState = (number) => {
  const baseUrl = `api/state/${number}`;
  return apiClient.get(`${baseUrl}`);
}

const getAdressesFromZipCode = async (zipcode) => {
  const dataFromZipcode = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
  return dataFromZipcode
}

export { getProfileDetails, getCities, updateProfileDetails, getAdressesFromZipCode };