import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { CustomersActionTypes } from "./actionTypes";
import { customersApiSuccess, customersApiFail } from "./action";

//Include Both Helper File with needed methods
import {
  getCustomersList as getCustomersListApi,
  getCustomerDetails as getCustomerDetailsApi,
  saveCustomerDetails as saveCustomerDetailsApi,
  getAddressByZipcode as getAddressByZipcodeApi,
  getMultiTelephones as getMultiTelephonesApi,
  createOrUpdateMultiTelephones as createOrUpdateMultiTelephonesApi,
  createCustomer as createCustomerApi,
} from "../../api/index";

// helper
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getCustomersList({ payload: filters = {} }) {
  try {
    const response = yield call(getCustomersListApi, filters);
    yield put(
      customersApiSuccess(CustomersActionTypes.GET_CUSTOMERS_LIST, response)
    );
  } catch (error) {
    yield put(customersApiFail(CustomersActionTypes.GET_CUSTOMERS_LIST, error));
  }
}

function* getCustomerDetails({ payload: customerId }) {
  try {
    const response = yield call(getCustomerDetailsApi, customerId);
    yield put(
      customersApiSuccess(CustomersActionTypes.GET_CUSTOMERS_DETAILS, response)
    );
  } catch (error) {
    yield put(
      customersApiFail(CustomersActionTypes.GET_CUSTOMERS_DETAILS, error)
    );
  }
}

function* saveCustomerDetails({ payload: { customerId, data } }) {
  try {
    const response = yield call(saveCustomerDetailsApi, customerId, data);
    yield put(
      customersApiSuccess(CustomersActionTypes.SAVE_CUSTOMERS_DETAILS, response)
    );
    yield call(
      showSuccessNotification,
      "Dados do cliente atualizados com sucesso!"
    );
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(
      customersApiFail(CustomersActionTypes.SAVE_CUSTOMERS_DETAILS, error)
    );
  }
}

function* getAddressByZipcode({ payload: zipcode }) {
  try {
    const param = {
      cep: zipcode,
    };
    const response = yield call(getAddressByZipcodeApi, param);
    yield put(
      customersApiSuccess(CustomersActionTypes.GET_ADDRES_BY_ZIPCODE, {
        id: zipcode,
        data: response,
      })
    );
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(
      customersApiFail(CustomersActionTypes.GET_ADDRES_BY_ZIPCODE, error)
    );
  }
}

function* getMultiTelephones({ payload: id }) {
  try {
    const response = yield call(getMultiTelephonesApi, id);
    yield put(
      customersApiSuccess(CustomersActionTypes.GET_MULTI_TELEPHONE, response)
    );
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(
      customersApiFail(CustomersActionTypes.GET_MULTI_TELEPHONE, error)
    );
  }
}

function* createCustomer({ payload: data }) {
  try {
    const response = yield call(createCustomerApi, data);
    yield put(
      customersApiSuccess(CustomersActionTypes.CREATE_CUSTOMER, response)
    );
  } catch (error) {
    yield put(
      customersApiFail(CustomersActionTypes.CREATE_CUSTOMER, error)
    );
  }
}

function* createOrUpdateMultiTelephones({ payload: id }) {
  try {
    const response = yield call(createOrUpdateMultiTelephonesApi, id);
    yield put(
      customersApiSuccess(CustomersActionTypes.SAVE_MULTITELEPHONES_DETAILS, response)
    );
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(
      customersApiFail(CustomersActionTypes.SAVE_MULTITELEPHONES_DETAILS, error)
    );
  }
}

export function* watchGetCustomersList() {
  yield takeEvery(CustomersActionTypes.GET_CUSTOMERS_LIST, getCustomersList);
}

export function* watchGetCustomerDetails() {
  yield takeEvery(
    CustomersActionTypes.GET_CUSTOMERS_DETAILS,
    getCustomerDetails
  );
}

export function* watchSaveCustomerDetails() {
  yield takeEvery(
    CustomersActionTypes.SAVE_CUSTOMERS_DETAILS,
    saveCustomerDetails
  );
}

export function* watchGetAddressByZipcode() {
  yield takeEvery(
    CustomersActionTypes.GET_ADDRES_BY_ZIPCODE,
    getAddressByZipcode
  );
}

export function* watchGetMultiTelephones() {
  yield takeEvery(
    CustomersActionTypes.GET_MULTI_TELEPHONE,
    getMultiTelephones
  );
}

export function* watchCreateOrUpdateMultiTelephones() {
  yield takeEvery(
    CustomersActionTypes.SAVE_MULTITELEPHONES_DETAILS,
    createOrUpdateMultiTelephones
  );
}

export function* watchCreateCustomer() {
  yield takeEvery(
    CustomersActionTypes.CREATE_CUSTOMER,
    createCustomer
  );
}


function* customersSaga() {
  yield all([
    fork(watchGetCustomersList),
    fork(watchGetCustomerDetails),
    fork(watchSaveCustomerDetails),
    fork(watchGetAddressByZipcode),
    fork(watchGetMultiTelephones),
    fork(watchCreateOrUpdateMultiTelephones),
    fork(watchCreateCustomer),
  ]);
}

export default customersSaga;
