import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Spinner,
  FormGroup,
} from "reactstrap";
import { mask, validaCpfCnpj, telMask, formatDate } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { storeTriangularShipping, consultaCep } from "../../../api"
import InputMask from "react-input-mask";
import { addEndValido, getCustomer } from "../../../store/actions";
import { toast } from "react-toastify";
import { validEmail, onlyNumbersAndLetters } from "../../../utils/otherUtils";
import { useSolar } from "../../../hooks";
import { useHistory } from "react-router-dom";

function ModalTriangularShipping({
  openModal,
  setOpenModal,
}) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { order, customer, frete } = useSelector(state => ({
    order: state.Order.orderActive.order,
    customer: state.Order.newOrder.newOrder.customer,
    frete: state.Order.newOrder.newOrder.frete,
  }));

  const isSolar = useSolar();

  const [nota, setNota] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cpfcnpj, setCpfcnpj] = useState("");
  const [dadosCliente, setDadosCliente] = useState({});
  const [endFat, setEndFat] = useState({});
  const [endEnt, setEndEnt] = useState({});
  const [dadosBanco, setDadosBanco] = useState({});
  const [sameEnd, setSameEnd] = useState(false);
  const [endEntValid, setEndEntValid] = useState(false);
  const [endFatValid, setEndFatValid] = useState(false);
  const [contFat, setContFat] = useState(0);
  const [contEnt, setContEnt] = useState(0);
  const [client, setClient] = useState({});
  const [loadingAddressEndFat, setLoadingAddressEndFat] = useState(false);
  const [loadingAddressEndEnt, setLoadingAddressEndEnt] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");

  const shippingCtpoper = ['04', '05', '54', '55'];

  const userNotFound = !!(customer.searched && !customer.data);

  const toggle = () => setOpenModal(state => !state);

  const insertShipping = async () => {
    let errors = checkErrors();

    if (errors.length > 0) {
      errors.map(value => {
        toast.warning(value, { theme: "colored" });
      });
      return false;
    }

    try {
      setLoading(true);

      const orderJson = JSON.stringify(order);

      const data = {
        orderJson,
        client: JSON.stringify(client),
        nota
      };

      const params = {
        id: order.NumPedido,
        data
      }

      await storeTriangularShipping(params).then((shipping) => {
        if (shipping.success) {
          toast.success("Alteração de remessa realizada com sucesso!",
            { theme: "colored" }
          );

          history.push(`/solar/pedidos/${shipping.order}/${order.CodFilial}/order`);
        } else {
          toast.error(shipping.message,
            { theme: "colored" }
          );
        }

        setLoading(false);
      });
    } catch (err) {
      setLoading(false);

      toast.error(err,
        { theme: "colored" }
      );
    }

  }

  const handleChangeAddress = (e, end, setEnd, endValid, setEndValid) => {
    const address = e.target.value;

    setEnd({
      ...end,
      [e.target.name]: address,
    });

    setEndValid({
      ...endValid,
      address: address
    });
  }

  useEffect(() => {
    updateEndFat();
  }, [endFat?.CEP]);

  useEffect(() => {
    findAddress(endEnt, setEndEnt, contEnt, setContEnt, setEndEntValid, setLoadingAddressEndEnt, true)
  }, [endEnt?.CEP]);

  const updateEndFat = () => {
    findAddress(endFat, setEndFat, contFat, setContFat, setEndFatValid, setLoadingAddressEndFat, false);
    if (sameEnd) {
      findAddress(endEnt, setEndEnt, contEnt, setContEnt, setEndEntValid, setLoadingAddressEndEnt, true);
    }
  }

  const findAddress = (end, setEnd, cont, setCont, setEndValid, setLoading, isDeliveryAddress) => {
    if (end.CEP?.replace(/[^0-9]+/g, "").length == 8 && cpfcnpj && userNotFound) {
      setEnd({
        ...end,
        UF: "...",
        MUNICIPIO: "...",
        ENDERECO: "...",
        BAIRRO: "..."
      });
      setLoading(true);
      consultaCep(end.CEP)
        .then(res => {
          addSearchedAddress(res, cont, setCont, end, setEnd, setEndValid, setLoading, isDeliveryAddress);
        })

        .catch(error => {
          catchFindAddress(error, end, setEnd, setEndValid, setLoading);
        });
    } else {
      setEndValid(false);
      setLoading(false);
    }
  };

  const addSearchedAddress = (response, cont, setCont, end, setEnd, setEndValid, setLoading, isDeliveryAddress) => {
    if (isDeliveryAddress) {
      dispatch(addEndValido(response.address));
    }
    setEndValid(response.address);
    setEnd({
      ...end,
      UF: response.address.state.trim(),
      MUNICIPIO: response.address.city.trim(),
      ENDERECO: response.address.address.trim()
        ? response.address.address.trim()
        : "",
      BAIRRO: response.address.district.trim()
        ? response.address.district.trim()
        : ""
    });

    setCont(cont + 1);
    setLoading(false);
  }

  const catchFindAddress = (error, end, setEnd, setEndValid, setLoading) => {
    setEnd({
      ...end,
      UF: "",
      MUNICIPIO: "",
      ENDERECO: "",
      BAIRRO: "",
      NumEnd: "",
      COMPL: ""
    });
    toast.error(error);
    setEndValid(false);
    setLoading(false);
  }

  useEffect(() => {
    if (validaCpfCnpj(cpfcnpj)) {
      dispatch(getCustomer({ cpfcnpj: cpfcnpj }));
      setDadosCliente({});
      setDadosBanco({});
      setEndFat({});
      setEndEnt({});
    } else {
      if (cpfcnpj.length == 11 || cpfcnpj.length == 14) {
        toast.warning(`CPF ou CNPJ digitado inválido. Tente novamente!`, {
          theme: "colored",
        });
      }
    }
  }, [cpfcnpj]);

  useEffect(() => {
    setDadosCliente({
      TP_PESSOA: customer.data?.TP_PESSOA.trim() || "F",
      Rg: customer.data?.Rg.trim() || "",
      Contrib: customer.data?.Contrib.trim() || "",
      Inscr: customer.data?.Inscr.trim() || "Isento",
      N_COMPLETO: customer.data?.N_COMPLETO.trim() || "",
      N_REDZ: customer.data?.N_REDZ.trim() || "",
      EMAIL: customer.data?.EMAIL.trim() || "",
      CONTATO: customer.data?.CONTATO.trim() || "",
      CargoCPD: customer.data?.CargoCPD.trim() || "",
      TEL: customer.data?.TEL.trim() || "",
      Tel2: customer.data?.CELULAR.trim() || "",
      DDDTEL: customer.data?.DDD.trim() || "",
      DDDTel2: customer.data?.DDD_CEL.trim() || "",
      DtNasc: formatDate(customer.data?.DtNasc.trim(), "YYYY-MM-DD") || "",
    });

    setEndFat({
      CEP: customer.data?.CEP.trim() || "",
      UF: customer.data?.UFCob.trim() || "",
      MUNICIPIO: customer.data?.MUNICIPIO.trim() || "",
      BAIRRO: customer.data?.BAIRRO.trim() || "",
      ENDERECO: customer.data?.ENDERECO.trim().split(",")[0] || "",
      NumEnd: customer.data?.NumEndCob.trim() || "",
      COMPL: customer.data?.COMPL.trim() || "",
    });

    setEndEnt({
      CEP: customer.data?.CEP_ENT.trim() || "",
      UF: customer.data?.UF_ENT.trim() || "",
      MUNICIPIO: customer.data?.MunicipEnt.trim() || "",
      BAIRRO: customer.data?.BAI_ENT.trim() || "",
      ENDERECO: customer.data?.END_ENT.trim().split(",")[0] || "",
      NumEnd: customer.data?.NumEndEnt.trim() || "",
      COMPL: customer.data?.ComplemEnt.trim() || "",
    });
  }, [customer.data]);

  useEffect(() => {
    setClient({
      id: customer?.data?.CODCLIENTE
        ? "000000" + customer?.data?.CODCLIENTE || ""
        : "",
      cpfcnpj: cpfcnpj || "",
      pessoa: cpfcnpj.length > 11 ? "J" : "F",
      customer_type:
        dadosCliente.TP_PESSOA || (cpfcnpj.length > 11 ? "J" : "F"),
      contribution:
        (cpfcnpj.length > 11 ? "J" : "F") == "F"
          ? 2
          : dadosCliente.Contrib || 2,
      social_number:
        (cpfcnpj.length > 11 ? "J" : "F") == "F"
          ? dadosCliente.Rg
          : dadosCliente.Inscr || "ISENTO",
      fullname: dadosCliente.N_COMPLETO || "",
      name_reduced: dadosCliente.N_REDZ || "",
      contact: dadosCliente.CONTATO || "",
      job_title: dadosCliente.CargoCPD || "",
      email: dadosCliente.EMAIL || "",
      dt_nasc:
        (cpfcnpj.length > 11 ? "J" : "F") == "F"
          ? dadosCliente.DtNasc || ""
          : "",
      loja: customer.data?.Loja || "",
      customer_ficha: {
        AGENCIA: dadosBanco.AGENCIA || "",
        CONTA: dadosBanco.CONTA || "",
        DTADMISS: dadosBanco.DTADMISS || "",
        CNPJTRAB: dadosBanco.CNPJTRAB || "",
        EMPTRAB: dadosBanco.EMPTRAB || "",
        CAPSOC: dadosBanco.CAPSOC || "",
        SOCIO: dadosBanco.SOCIO || "",
        FATMEN: dadosBanco.FATMEN || "",
        FUNCEMP: dadosBanco.FUNCEMP || "",
        CPFREF: dadosBanco.CPFREF || "",
        CODIBGEC: endFatValid?.cidadde_info?.codigo_ibge || "",
        CODIBGEE: endEntValid?.cidadde_info?.codigo_ibge || "",
      },
      customer_address: [
        {
          zipcode: (endFat.CEP || "").replace(/[^0-9]+/g, ""),
          district: endFat.BAIRRO || "",
          city: (endFat.MUNICIPIO || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: endFat.UF || "",
          address: endFat.ENDERECO || "",
          number: endFat.NumEnd || "",
          complement: endFat.COMPL || "",
        },
        {
          zipcode: (endEnt.CEP || "").replace(/[^0-9]+/g, ""),
          district: endEnt.BAIRRO || "",
          city: (endEnt.MUNICIPIO || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: endEnt.UF || "",
          address: endEnt.ENDERECO || "",
          number: endEnt.NumEnd || "",
          complement: endEnt.COMPL || "",
        },
      ],
      customer_phones: [
        {
          ddd: dadosCliente.DDDTEL || "",
          phone: dadosCliente.TEL || "",
        },
        {
          ddd: dadosCliente.DDDTel2 || "",
          phone: dadosCliente.Tel2 || "",
        },
      ],
    });
  }, [cpfcnpj, dadosCliente, endFat, endEnt, dadosBanco]);

  const verifyEmail = e => {
    setDadosCliente({
      ...dadosCliente,
      [e.target.name]: e.target.value,
    });
    const isValid = validEmail(e.target.value);
    if (isValid) {
      setEmailMessage("");
    } else {
      setEmailMessage("Digite um email válido");
    }
  };

  const checkErrors = () => {
    let erros = [];

    if (!customer.data) {
      if (!client.email) {
        erros.push("Email não preenchido.");
      }

      if (client.cpfcnpj.length <= 11) {
        if (
          !client.dt_nasc ||
          client.dt_nasc === "Data inválida"
        ) {
          erros.push("Data de nascimento inválida");
        }
      }

      if (!client.customer_phones[1].phone) {
        erros.push("Celular não preenchido.");
      }

      if (!client.customer_address[0].number) {
        erros.push("Numero de faturamento não preenchido.");
      }

      if (!client.customer_address[1].number) {
        erros.push("Numero de entrega não preenchido. ");
      }

      if (!client.customer_phones[0].phone) {
        erros.push("Telefone não preenchido.");
      }

      if (!(client.fullname && client.name_reduced)) {
        erros.push("Nome e nome reduzido não preenchido.");
      }

      if (client.pessoa == "F" && !client.dt_nasc) {
        erros.push("Data de Nascimento não preenchida.");
      }

      if (!(client.customer_address[0].zipcode &&
        client.customer_address[0].address &&
        client.customer_address[0].number
      )) {
        erros.push("Endereço de cobrança não preenchido.");
      }

      if (!(
        client.customer_address[1].zipcode &&
        client.customer_address[1].address &&
        client.customer_address[1].number
      )) {
        erros.push("Endereço de entrega não preenchido.");
      }
    }

    if (!nota) {
      erros.push("Nota fiscal de remessa não foi inserida.")
    }

    return erros;
  };

  return (
    <>
      {(isSolar && (shippingCtpoper.includes(order.items_orcamento[0].CTPOPER))) && (
        <>
          {order.pedidoAtrelado ? (
            <a
              className="btn btn-rounded bg-white shadow me-3 px-3 botoes-itens"
              target="_blank"
              rel="noreferrer"
              href={`/solar/pedidos/${String(order.pedidoAtrelado).padStart(
                6,
                "0"
              )}/${order.pedidoAtreladoFilial}/order`}
            >
              <i className="ph-package icones-status"></i>
              {order.items_orcamento[0].CTPOPER === "05" || order.items_orcamento[0].CTPOPER === "55"
                ? "Visualizar Venda Triangular"
                : "Visualizar Remessa Triangular"}
            </a>
          ) : (
            <>
              <a
                className="btn btn-rounded bg-white shadow me-3 px-3 botoes-itens"
                onClick={() => setOpenModal(true)}
              >
                <i className="ph-package icones-status"></i>
                Gerar Pedido Remessa
              </a>

              <Modal
                className="modal-insert-product"
                isOpen={openModal}
                toggle={toggle}
                size="lg"
              >
                <ModalHeader toggle={toggle}>ALTERAR REMESSA</ModalHeader>
                <ModalBody>
                  {loading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <Row>
                        <Col md={6}>
                          <Label>CPF/CNPJ</Label>
                          <Input
                            id="cpfcnpj"
                            value={mask(cpfcnpj)}
                            onChange={e =>
                              setCpfcnpj(e.target.value.replace(/[^0-9]+/g, ""))
                            }
                            maxLength={18}
                          />
                        </Col>
                        <Col md={6}>
                          <Label>Tipo de pessoa</Label>
                          <select
                            className="form-control"
                            value={cpfcnpj.length > 11 ? "J" : "F"}
                            disabled
                            readOnly
                          >
                            <option value="F">Física</option>
                            <option value="J">Jurídica</option>
                          </select>
                        </Col>
                      </Row>
                      {userNotFound && (
                        <Row className="mt-3">
                          {(cpfcnpj.length > 11 ? "J" : "F") == "J" ? (
                            <React.Fragment>
                              <Col md={6}>
                                <Label>Tipo Inscrição</Label>
                                <select
                                  className="form-control"
                                  value={dadosCliente.Contrib || 2}
                                  name="Contrib"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                >
                                  <option value={2}>ISENTO</option>
                                  <option value={1}>Inscrição Estadual</option>
                                </select>
                              </Col>
                              <Col md={6}>
                                <Label>Inscrição Estadual</Label>
                                <Input
                                  value={
                                    dadosCliente.Contrib == 2
                                      ? "ISENTO"
                                      : dadosCliente.Inscr || ""
                                  }
                                  name="Inscr"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  readOnly={dadosCliente.Contrib == 2}
                                />
                              </Col>
                            </React.Fragment>
                          ) : (
                            <Col md={6}>
                              <Label>RG da Inscrição</Label>
                              <Input
                                placeholder="00.000.000-0"
                                value={dadosCliente.Rg || ""}
                                name="Rg"
                                onChange={e =>
                                  setDadosCliente({
                                    ...dadosCliente,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      )}
                      {customer.loading && <Spinner className="mt-4" />}
                      {userNotFound && (
                        <div>
                          <h5 className="h5 mt-4">Dados do Cliente</h5>
                          <FormGroup>
                            <Row className="mt-4">
                              <Col md={6}>
                                <Label>
                                  {(cpfcnpj.length > 11 ? "J" : "F") == "J"
                                    ? "Razão social"
                                    : "Nome Completo"}
                                </Label>
                                <Input
                                  value={dadosCliente.N_COMPLETO || ""}
                                  name="N_COMPLETO"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col md={6}>
                                <Label>Nome fantasia / Nome Reduzido</Label>
                                <Input
                                  value={dadosCliente.N_REDZ || ""}
                                  name="N_REDZ"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={cpfcnpj.length > 11 ? 12 : 6}>
                                <Label>E-mail</Label>
                                <Input
                                  value={dadosCliente.EMAIL || ""}
                                  name="EMAIL"
                                  onChange={verifyEmail}
                                />
                                <p className="erros">
                                  {emailMessage}
                                </p>
                              </Col>
                              {(cpfcnpj.length > 11 ? "J" : "F") == "J" || (
                                <Col md={6}>
                                  <Label>Data de Nascimento</Label>

                                  <input
                                    className="form-control d-block"
                                    type="date"
                                    value={dadosCliente.DtNasc}
                                    onChange={e =>
                                      setDadosCliente({
                                        ...dadosCliente,
                                        DtNasc: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              )}
                              <Col md={6}>
                                <Label>Contato</Label>
                                <Input
                                  value={dadosCliente.CONTATO || ""}
                                  name="CONTATO"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col md={6}>
                                <Label>Cargo</Label>
                                <Input
                                  value={dadosCliente.CargoCPD || ""}
                                  name="CargoCPD"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={6}>
                                <Label>Telefone Comercial (FIXO)</Label>
                                <Row>
                                  <Col md={2}>
                                    <Input
                                      value={dadosCliente.DDDTEL || ""}
                                      name="DDDTEL"
                                      maxLength={2}
                                      onChange={e =>
                                        setDadosCliente({
                                          ...dadosCliente,
                                          [e.target.name]: e.target.value,
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col md={10}>
                                    <Input
                                      value={
                                        dadosCliente.TEL?.length > 4
                                          ? telMask(dadosCliente.TEL)
                                          : dadosCliente.TEL || ""
                                      }
                                      name="TEL"
                                      onChange={e =>
                                        setDadosCliente({
                                          ...dadosCliente,
                                          [e.target.name]: e.target.value.replace(
                                            /[^0-9]+/g,
                                            ""
                                          ),
                                        })
                                      }
                                      maxLength={10}
                                      minLength={9}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6}>
                                <Label>Telefone Celular</Label>
                                <Row>
                                  <Col md={2}>
                                    <Input
                                      value={dadosCliente.DDDTel2 || ""}
                                      maxLength={2}
                                      name="DDDTel2"
                                      onChange={e =>
                                        setDadosCliente({
                                          ...dadosCliente,
                                          [e.target.name]: e.target.value,
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col md={10}>
                                    <Input
                                      value={
                                        dadosCliente.Tel2?.length > 4
                                          ? telMask(dadosCliente.Tel2)
                                          : dadosCliente.Tel2 || ""
                                      }
                                      name="Tel2"
                                      onChange={e =>
                                        setDadosCliente({
                                          ...dadosCliente,
                                          [e.target.name]: e.target.value.replace(
                                            /[^0-9]+/g,
                                            ""
                                          ),
                                        })
                                      }
                                      maxLength={10}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input form-check-input-solar"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    style={{ color: "#FF601A" }}
                                  />
                                  <label
                                    className="form-check-label form-check-input-solar d-flex align-items-center"
                                    style={{ color: "#FF601A" }}
                                  >
                                    Permitir contato via whatsapp{" "}
                                    <i
                                      style={{ marginLeft: 10 }}
                                      className="ph-whatsapp-logo"
                                    ></i>
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                          <h5 className="h5 mt-4">
                            Endereço Faturamento e Cobrança
                          </h5>
                          <FormGroup>
                            <Row className="mt-4">
                              <Col md={3}>
                                <Label>CEP</Label>
                                <InputMask
                                  mask="99999-999"
                                  value={endFat.CEP || ""}
                                  className="form-control"
                                  name="CEP"
                                  disabled={frete.loading || loadingAddressEndFat || customer?.data}
                                  onChange={e => {
                                    setEndFat({
                                      ...endFat,
                                      [e.target.name]: e.target.value,
                                    });
                                    if (sameEnd)
                                      setEndEnt({
                                        ...endFat,
                                        [e.target.name]: e.target.value,
                                      });
                                  }}
                                />{" "}
                                {(frete.loading) && (
                                  <p style={{ marginTop: 5 }}>
                                    Calculando valor do frete...
                                  </p>
                                )}

                                {(loadingAddressEndFat) && (
                                  <p style={{ marginTop: 5 }}>
                                    Buscando dados do cep...
                                  </p>
                                )}
                              </Col>
                              <Col md={3}>
                                <Label>Estado</Label>
                                <Input
                                  value={endFatValid.state || endFat.UF || ""}
                                  name="UF"
                                  onChange={e => {
                                    setEndFat({
                                      ...endFat,
                                      [e.target.name]: e.target.value,
                                    });
                                    if (sameEnd)
                                      setEndEnt({
                                        ...endFat,
                                        [e.target.name]: e.target.value,
                                      });
                                  }}
                                  readOnly={!endFatValid}
                                  disabled
                                />
                              </Col>
                              <Col md={3}>
                                <Label>Cidade</Label>
                                <Input
                                  value={endFatValid.city || endFat.MUNICIPIO || ""}
                                  name="MUNICIPIO"
                                  onChange={e => {
                                    setEndFat({
                                      ...endFat,
                                      [e.target.name]: e.target.value,
                                    });
                                    if (sameEnd)
                                      setEndEnt({
                                        ...endFat,
                                        [e.target.name]: e.target.value,
                                      });
                                  }}
                                  readOnly={!endFatValid}
                                  disabled
                                />
                              </Col>
                              <Col md={3}>
                                <Label>Bairro</Label>
                                <Input
                                  value={
                                    endFat.BAIRRO || endFatValid.district || ""
                                  }
                                  name="BAIRRO"
                                  onChange={e => {
                                    setEndFat({
                                      ...endFat,
                                      [e.target.name]: e.target.value,
                                    });
                                    if (sameEnd)
                                      setEndEnt({
                                        ...endFat,
                                        [e.target.name]: e.target.value,
                                      });
                                  }}
                                  readOnly={!endFatValid}
                                  disabled={loadingAddressEndFat}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={4}>
                                <Label>Endereço</Label>
                                <Input
                                  value={
                                    endFat.ENDERECO || endFatValid.address || ""
                                  }
                                  name="ENDERECO"
                                  onChange={e => {
                                    handleChangeAddress(e, endFat, setEndFat, endFatValid, setEndFatValid);
                                    if (sameEnd) {
                                      handleChangeAddress(e, endEnt, setEndEnt, endEntValid, setEndEntValid);
                                    }
                                  }}
                                  readOnly={!endFatValid}
                                  disabled={loadingAddressEndFat}
                                />
                              </Col>
                              <Col md={4}>
                                <Label>Número</Label>
                                <Input
                                  value={endFat.NumEnd || ""}
                                  name="NumEnd"
                                  maxLength={6}
                                  onChange={e => {
                                    setEndFat({
                                      ...endFat,
                                      [e.target.name]: onlyNumbersAndLetters(e.target.value),
                                    });
                                    if (sameEnd)
                                      setEndEnt({
                                        ...endFat,
                                        [e.target.name]: onlyNumbersAndLetters(e.target.value),
                                      });
                                  }}
                                  readOnly={!endFatValid}
                                  disabled={loadingAddressEndFat}
                                />
                              </Col>
                              <Col md={4}>
                                <Label>Complemento</Label>
                                <Input
                                  value={endFat.COMPL || ""}
                                  name="COMPL"
                                  onChange={e => {
                                    setEndFat({
                                      ...endFat,
                                      [e.target.name]: e.target.value,
                                    });
                                    if (sameEnd)
                                      setEndEnt({
                                        ...endFat,
                                        [e.target.name]: e.target.value,
                                      });
                                  }}
                                  readOnly={!endFatValid}
                                  disabled={loadingAddressEndFat}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <h5 className="h5 mt-4">Endereço Entrega</h5>
                          <FormGroup>
                            <FormGroup check className="mt-4">
                              <Input
                                type="checkbox"
                                checked={sameEnd}
                                className="endCheck"
                                onChange={e => {
                                  setSameEnd(!sameEnd);
                                  if (e.target.checked) {
                                    setEndEntValid(endFatValid);
                                    setEndEnt(endFat);
                                  };
                                }}
                              />
                              <Label check>
                                Usar o mesmo endereço para entrega
                              </Label>
                            </FormGroup>
                            <Row className="mt-4">
                              <Col md={3}>
                                <Label>CEP</Label>
                                <InputMask
                                  mask="99999-999"
                                  value={endEnt.CEP || ""}
                                  readOnly={sameEnd}
                                  className="form-control"
                                  name="CEP"
                                  disabled={frete.loading || loadingAddressEndEnt}
                                  onChange={e =>
                                    setEndEnt({
                                      ...endEnt,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />{" "}
                                {frete.loading && (
                                  <p style={{ marginTop: 5 }}>
                                    Calculando valor do frete...
                                  </p>
                                )}
                                {loadingAddressEndEnt && (
                                  <p style={{ marginTop: 5 }}>
                                    Buscando dados do cep...
                                  </p>
                                )}
                              </Col>
                              <Col md={3}>
                                <Label>Estado</Label>
                                <Input
                                  value={endEntValid.state || endEnt.UF || ""}
                                  readOnly={sameEnd || !endEntValid}
                                  name="UF"
                                  onChange={e =>
                                    setEndEnt({
                                      ...endEnt,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={3}>
                                <Label>Cidade</Label>
                                <Input
                                  value={endEntValid.city || endEnt.MUNICIPIO || ""}
                                  readOnly={sameEnd || !endEntValid}
                                  name="MUNICIPIO"
                                  onChange={e =>
                                    setEndEnt({
                                      ...endEnt,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={3}>
                                <Label>Bairro</Label>
                                <Input
                                  value={
                                    endEnt.BAIRRO || endEntValid.district || ""
                                  }
                                  readOnly={sameEnd || !endEntValid}
                                  name="BAIRRO"
                                  onChange={e =>
                                    setEndEnt({
                                      ...endEnt,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  disabled={loadingAddressEndEnt}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={4}>
                                <Label>Endereço</Label>
                                <Input
                                  value={
                                    endEnt.ENDERECO || endEntValid.address || ""
                                  }
                                  readOnly={sameEnd || !endEntValid}
                                  name="ENDERECO"
                                  onChange={e =>
                                    handleChangeAddress(e, endEnt, setEndEnt, endEntValid, setEndEntValid)
                                  }
                                  disabled={loadingAddressEndEnt}
                                />
                              </Col>
                              <Col md={4}>
                                <Label>Número</Label>
                                <Input
                                  value={endEnt.NumEnd || ""}
                                  readOnly={sameEnd || !endEntValid}
                                  name="NumEnd"
                                  maxLength={6}
                                  onChange={e =>
                                    setEndEnt({
                                      ...endEnt,
                                      [e.target.name]: onlyNumbersAndLetters(e.target.value),
                                    })
                                  }
                                  disabled={loadingAddressEndEnt}
                                />
                              </Col>
                              <Col md={4}>
                                <Label>Complemento</Label>
                                <Input
                                  value={endEnt.COMPL || ""}
                                  readOnly={sameEnd || !endEntValid}
                                  name="COMPL"
                                  onChange={e =>
                                    setEndEnt({
                                      ...endEnt,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  disabled={loadingAddressEndEnt}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col md={12}>
                          <Label>Nota de remessa:</Label><br />
                          <Input type="file" onChange={(e) => setNota(e.target.files[0])} />
                        </Col>
                      </Row>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={toggle}>
                    Fechar
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={insertShipping}
                    className="button-save"
                  >
                    Salvar
                  </Button>
                </ModalFooter>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ModalTriangularShipping;
