import React, { useState, useEffect } from "react";
import {
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip
} from "reactstrap";
import { formato } from "../../../utils";
import {
  emitirPedido,
  editarPedido
} from "../../../store/actions";
import { getFrete, getTermos, saveProspectClient } from "../../../api/index";
import { useDispatch, useSelector } from "react-redux";
import { validaCpfCnpj } from "../../../utils";
import { useHistory, useParams } from "react-router-dom";
import { useSolar } from "../../../hooks";
import { toast } from "react-toastify";
import FutureSale from "./FutureSale";

const Resumo = ({
  frete,
  saldo,
  pedido,
  changePedidoEmitido,
  valRt,
  changeType,
  formVisible,
  token,
  setLoadingProspect,
  cepSelected,
  getTheHighestForecastDate,
  total,
  discountValue,
  setFutureSaleValue,
  triangularSale,
  setTriangularSale,
  shippingZipCode,
  completedShippingValues,
  largeAccountCode,
  shippingTotalValue,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { emissaoPedido, order, emailExists, newOrder } = useSelector(
    state => ({
      order: state.Order.orderActive.order,
      emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
      newOrder: state.Order.newOrder.newOrder,
      emailExists: state.Order.dataEmailExists,
    })
  );

  const [aceiteTerm, setAceiteTerm] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [modalTerm, setModalTerm] = useState(false);
  const [modalPedido, setModalPedido] = useState({ open: false, name: "" });
  const discount =
    discountValue?.typerulediscount == 1
      ? (parseFloat(total) / 100) * Number(discountValue?.discountValue)
      : discountValue?.discountValue;

  var totalDiscount = parseFloat(total) - parseFloat(discount || 0);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isSolar = useSolar();

  const [termos, setTermos] = useState(<></>);

  useEffect(() => {
    const getTermosApi = async company => {
      const response = await getTermos(company);
      setTermos(response);
    };

    getTermosApi(isSolar ? "02" : "01");
  }, []);

  const validarErros = typeFormIsClient => {
    let erros = [];

    if (typeFormIsClient) {
      if (!validaCpfCnpj(pedido.client.cpfcnpj))
        erros.push("CPF ou CNPJ inválido. Revise e tente novamente!");
    }

    if (pedido.items.length < 1)
      erros.push("Não é possível emitir pedido com carrinho vazio.");

    if (!pedido.shipping.id) {
      if (cepSelected != "retirarNaLoja") {
        erros.push("Frete não selecionado.");
      }
    }

    if (!pedido.client.email) erros.push("Email não preenchido.");

    if (typeFormIsClient) {
      if (pedido.client.cpfcnpj.length <= 11) {
        if (
          !pedido.client.dt_nasc ||
          pedido.client.dt_nasc === "Data inválida"
        ) {
          erros.push("Data de nascimento inválida");
        }
      }
      if (!pedido.client.customer_phones[1].phone)
        erros.push("Celular não preenchido.");
      if (!pedido.client.customer_address[0].number)
        erros.push("Numero de faturamento não preenchido.");
      if (!pedido.client.customer_address[1].number)
        erros.push("Numero de entrega não preenchido.");
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Telefone não preenchido.");
    } else {
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Celular não preenchido.");
    }

    if (typeFormIsClient) {
      if (!(pedido.client.fullname && pedido.client.name_reduced))
        erros.push("Nome e nome reduzido não preenchido.");
    } else {
      if (!pedido.client.fullname) erros.push("Nome  não preenchido.");
    }

    if (typeFormIsClient) {
      if (pedido.client.pessoa == "F" && !pedido.client.dt_nasc)
        erros.push("Data de Nascimento não preenchida.");
    }

    if (
      !(
        pedido.client.customer_address[0].zipcode &&
        pedido.client.customer_address[0].address &&
        pedido.client.customer_address[0].number
      )
    )
      erros.push("Endereço de cobrança não preenchido.");

    if (typeFormIsClient) {
      if (
        !(
          pedido.client.customer_address[1].zipcode &&
          pedido.client.customer_address[1].address &&
          pedido.client.customer_address[1].number
        )
      )
        erros.push("Endereço de entrega não preenchido.");
    }

    if (triangularSale) {
      if (shippingZipCode.length == 0) {
        erros.push("CEP de remessa não preenchido.");
      }

      if (!completedShippingValues) {
        erros.push("É necessário adicionar o valor de remessa de todos os itens no carrinho!");
      }
    }

    if (!aceiteTerm) {
      erros.push(
        "É preciso aceitar os termos de fornecimento para emitir pedidos."
      );
    }
    return erros;
  };

  const openModal = type => {
    let erros = validarErros(formVisible.client);
    if (erros.length > 0) {
      erros.map(value => {
        toast.warning(value, { theme: "colored" });
      });
      return false;
    }
    if (type === "order") {
      token
        ? history.push("/solar/ordenar-por-consumo")
        : setModalPedido({ open: true, name: "order" });
    } else {
      setModalPedido({ open: true, name: "budget" });
    }
  };

  const emitirNovoPedido = async type => {
    var objDataProspect = {};
    var dataSave = null;
    setLoadingProspect(true);

    if (cepSelected == "retirarNaLoja") {
      await retirarNaLoja();
    }

    if (aceiteTerm) {
      if (id) {
        dispatch(
          editarPedido({
            id: order.NumPedido,
            pedido,
          })
        );
      } else {
        if (type == "order") {
          changeType("order");
          dispatch(emitirPedido(pedido));
        }
        if (type == "budget") {
          if (emailExists?.emailExists == "false") {
            objDataProspect = {
              CODEMPRESA: "02",
              CODFILIAL: "02",
              nome: pedido?.client?.fullname,
              email: pedido?.client?.email,
              ddd: pedido?.client?.customer_phones[0]?.ddd,
              telefone: pedido?.client?.customer_phones[0]?.phone,
              cep: pedido?.client?.customer_address[0]?.zipcode,
              endereco: pedido?.client?.customer_address[0]?.address,
              endereco_numero: pedido?.client?.customer_address[0]?.number,
              bairro: pedido?.client?.customer_address[0]?.district,
              cidade: pedido?.client?.customer_address[0]?.city,
              uf: pedido?.client?.customer_address[0]?.state,
              complemento: pedido?.client?.customer_address[0]?.complement,
            };

            dataSave = await saveProspectClient(objDataProspect);

            changeType("budget");

            dispatch(
              emitirPedido({
                ...pedido,
                type_order: "budget",
                lead_id: token,
                budget_infos: {
                  codigo_cliente:
                    dataSave?.message ==
                      "Já existe um prospect com o email informado"
                      ? dataSave.data.prospect_id
                      : dataSave.prospect,

                  tipo_cliente: "prospect",
                },
              })
            );
            setLoadingProspect(false);
          } else {
            changeType("budget");
            dispatch(
              emitirPedido({
                ...pedido,
                type_order: "budget",
                lead_id: token,
                budget_infos: {
                  codigo_cliente: pedido.client.id,
                  tipo_cliente: 0,
                },
              })
            );
          }
        }
      }
    } else {
      toast.warning(
        `É preciso aceitar os termos de fornecimento para emitir pedidos.`,
        { theme: "colored" }
      );
    }

    setModalPedido({ open: !modalPedido, name: "" });
    setLoadingProspect(false);
  };

  const retirarNaLoja = async () => {
    //dados fixos de ipojuca
    const dataEndIpojuca = {
      zipcode: "55590-000",
      district: "IPOJUCA",
      city: "PERNAMBUCO",
      state: "PE",
      address: "ROD PE 060",
      number: "10",
      complement: "ZONA IND PORTO SUAPELOTE PORTO DE SUAPE",
    };
    //dados fixos de cascavel
    const dataEndCascavel = {
      zipcode: "85816-455",
      district: "CASCAVEL",
      city: "PARANÁ",
      state: "PR",
      address: "AV ARACY TANAKA BIAZZETO",
      number: "6508",
      complement: "SALA 160",
    };

    //dados fixos de cascavel
    const dataEndSaoPaulo = {
      zipcode: "13186-546",
      district: "JARDIM SANTA ESMERALDA",
      city: "HORTOLÂNDIA",
      state: "SP",
      address: "RUA PÉROLA",
      number: "200",
      complement: "LOTE 01A GALPÃO 01",
    };
    //verifica qual a empresa esta seleciona e pega os dados do endereço do local dessa empresa: cascavel / ipojuca
    var validCepLocationData = { ...dataEndSaoPaulo };

    //adiciona os dados novo endereço dentro do pedido
    pedido.client.customer_address = formVisible.client
      ? [validCepLocationData, validCepLocationData]
      : [validCepLocationData];
    //busca na api os dados do frete
    const resp = await getFrete({
      zipcode: validCepLocationData.zipcode,
      valTot: triangularSale ? shippingTotalValue : total,
      company: "02",
      filial: "01",
      date: getTheHighestForecastDate,
    });
    var freteSelect = resp.shippingMethods[0];
    //seta os novos dados do frete dentro do pedido
    pedido.shipping = {
      id: freteSelect?.id || "",
      shipping_selected: {
        delivery_delay: freteSelect?.delivery_delay || "",
        id: freteSelect?.id || "",
        price: freteSelect?.price || 0,
        description: freteSelect?.description || "",
        price_percent: freteSelect?.price_percent || 0,
        usado_percentual: freteSelect?.usado_percentual || 0,
        frete_gratis: freteSelect?.frete_gratis || "",
      },
      price: freteSelect?.price || 0,
    };

    return await Promise.resolve("finish");
  };

  useEffect(() => {
    if (!emissaoPedido.loading && emissaoPedido.data && fetch) {
      if (emissaoPedido.data?.success) {
        changePedidoEmitido();
      }
      setFetch(false);
    }
  }, [emissaoPedido, fetch]);

  useEffect(() => {
    largeAccountCode.includes(pedido.filters?.condpagto) ? setTriangularSale(true) : setTriangularSale(false);
  }, [pedido.filters?.condpagto])

  const financingCode = ['853', '854', '855', '856'];

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4
            style={{
              fontSize: 23,
              fontWeight: "bold",
              color: "#202833",
              marginBottom: 25,
            }}
          >
            Resumo
          </h4>
          <Table className="mt-0 tabela-resumo mb-0" borderless>
            <tbody>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Valor dos Produtos
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(total).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Frete
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(frete?.price || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Desconto Aplicado
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {"- " + (discount || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                <td style={{ padding: "0 0 17px 0" }} colSpan={2}>
                  Saldo em conta utilizado
                </td>
                <td style={{ padding: "0 0 17px 0", textAlign: "end" }}>
                  {(saldo || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "17px 0 17px 0",
                  }}
                >
                  Cashback Wattpay
                  <i
                    className="ph-coin"
                    style={{ fontSize: 15, marginLeft: 10 }}
                  ></i>
                </td>
                { }
                <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}></td>
                <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                  {(valRt || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "20px 0 35px 0" }} colSpan={2}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                    }}
                  >
                    Valor Total
                  </h4>
                </td>
                <td style={{ padding: "20px 0 35px 0" }}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                      marginBottom: 25,
                      textAlign: "end",
                    }}
                  >
                    {parseFloat(
                      parseFloat(totalDiscount) +
                      parseFloat(frete?.price || 0) -
                      parseFloat(saldo)
                    ).toLocaleString("pt-br", formato)}
                  </h4>
                </td>
              </tr>
            </tbody>
          </Table>
          <FormGroup check className="d-flex">
            <Input
              type="checkbox"
              value="A"
              className="modalidade aceite"
              onClick={e => setAceiteTerm(e.target.checked)}
            />
            <Label
              className={aceiteTerm ? "label-prazo-active" : ""}
              style={{ marginLeft: 20, fontWeight: 500 }}
            >
              Li e concordo com os{" "}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setModalTerm(!modalTerm);
                }}
              >
                Termos e condições <br />
                de emissão de pedidos e orçamentos
              </a>
            </Label>
          </FormGroup>
          {(!financingCode.includes(pedido.filters?.condpagto) &&
            !largeAccountCode.includes(pedido.filters?.condpagto)) ? (
            <FutureSale
              futureSaleValue={pedido.futureSale}
              setFutureSaleValue={setFutureSaleValue}
            />
          ) : (
            ""
          )}

          {emissaoPedido.loading && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}

          <Button
            style={{
              backgroundColor: "#FF601A",
              color: "#FFFFFF",
              width: "100%",
              marginTop: 35,
              display: "block",
              fontSize: 17,
              border: "none",
              padding: 20,
              borderRadius: 6,
            }}
            onClick={() => {
              openModal("budget");
            }}
          >
            {id ? "EDITAR ORÇAMENTO" : "EMITIR ORÇAMENTO"}
          </Button>

          {!token && (
            <>
              <Button
                style={{
                  backgroundColor: !formVisible.client ? "#d6d0cb" : "#FFF7F2",
                  color: !formVisible.client ? "#a6a19d" : "#FF601A",
                  width: "100%",
                  marginTop: 30,
                  display: "block",
                  fontSize: 17,
                  border: "none",
                  padding: 20,
                  borderRadius: 6,
                  cursor: !formVisible.client ? "not-allowed" : "pointer",
                }}
                id="emitir-pedido"
                onClick={() => {
                  if (formVisible.client) {
                    openModal("order");
                  }
                }}
              >
                {token ? "VOLTAR PARA CALCULADORA" : "EMITIR PEDIDO"}
              </Button>
              {!formVisible.client ? (
                <Tooltip
                  target={"emitir-pedido"}
                  isOpen={isTooltipOpen}
                  placement="bottom"
                  toggle={() => setIsTooltipOpen(prev => !prev)}
                  style={{ backgroundColor: "rgb(255, 94, 0)" }}
                >
                  Para emitir pedido é necessario utilizar a opção
                  &#34;Cliente&#34;
                </Tooltip>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={modalPedido.open}
        size="sm"
        toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
      >
        <ModalHeader
          toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
          className="w-100 justify-content-center"
        >
          Deseja emitir o{" "}
          {modalPedido.name == "budget" ? "orçamento?" : "pedido?"}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center gap-5">
          <Button
            style={{
              backgroundColor: "#FF601A",
              color: "white",
              minWidth: 83,
            }}
            onClick={() => {
              emitirNovoPedido(
                modalPedido.name == "budget" ? "budget" : "order"
              );
              setFetch(true);
            }}
          >
            Confirmar
          </Button>
          <Button
            style={{ backgroundColor: "#E0E0E0", color: "#787878" }}
            onClick={() => setModalPedido({ open: !modalPedido, name: "" })}
          >
            Cancelar
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalTerm}
        size="lg"
        toggle={() => setModalTerm(!modalTerm)}
      >
        <ModalHeader toggle={() => setModalTerm(!modalTerm)}>
          Termos e condições de emissão de pedidos e orçamentos
        </ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: termos }}></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Resumo;
