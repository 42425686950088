import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";

//i18n
import { withTranslation } from "react-i18next";
import { useSolar } from "../../../hooks";
import { apiClient2 } from "../../../api/apiCore2";
import { apiClient } from "../../../api/apiCore";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const isSolar = useSolar();

  const getNotifications = async () => {
    try {
      const resp = await apiClient.get(
        "/api/notifications-html"
      );
      if(typeof(resp) != "object") {
        let lenght = resp?.indexOf("}");
        let objRemove = resp?.substr(lenght + 1);
        let objFormatted = JSON.parse(objRemove);
        setNotifications(objFormatted.notifications);
      } 
      

     
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const markAsViewer = async id => {
    await apiClient2.get(`/api/notifications/show/${id}`);
  };

  const formatDate = date => {
    var format = date.substring(0, 10);

    return format.replaceAll("-", "");
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <span
            className="badge rounded-pill"
            style={{
              backgroundColor: isSolar ? "#FF601A" : "#2D567B",
              fontSize: "65%",
              top: "36px",
              right: "26px",
            }}
          >
            {notifications.length}
          </span>
          <NotificationsNoneOutlinedIcon size={22} weight="bold" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  {props.t("Notifications")}{" "}
                </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  {/*    Mark all as read */}
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.length == 0 ? (
              <Link to="" className="text-reset notification-item">
                <div className="d-flex">
                  <div className="flex-1">
                    <h6 className="fw-normal mt-0 mb-1">
                      Você tem 0 notificações não lidas
                    </h6>{" "}
                  </div>
                </div>
              </Link>
            ) : (
              notifications
                .sort((a, b) => {
                  if (a.id > b.id) {
                    return 1;
                  }
                  if (a.id < b.id) {
                    return -1;
                  }
                })
                .map((item, k) => (
                  <Link
                    key={k}
                    to={isSolar ? "/solar" + item.link : item.link}
                    className="text-reset notification-item"
                    onClick={() => markAsViewer(item.id)}
                  >
                    <div className="d-flex">
                      <div className="flex-1">
                        <h6 className="fw-normal mt-0 mb-1">{item.message}</h6>
                        <span style={{ color: "#9c9c9f" }}>
                          {moment(
                            formatDate(item.created_at),
                            "YYYYMMDD"
                          ).fromNow()}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))
            )}
          </SimpleBar>
          <div className="p-2 border-top border-light-1 d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to={isSolar ? "/solar/notificacoes" : "/notificacoes"}
              onClick={() => setMenu(!menu)}
            >
              <i className="uil-arrow-circle-right me-1"></i>{" "}
              {props.t("Ver todas notificações")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(NotificationDropdown);
